import { configureStore } from '@reduxjs/toolkit';

//Slices
import eleccionIndex from './slices/eleccionIndex';
import buscadorActivo from './slices/buscadorActivo';
import datosEncontrados from './slices/datosEncontrados';

export default configureStore({
    reducer: {
        eleccionIndex,
        buscadorActivo,
        datosEncontrados
    }
});