import React from 'react';
import { opcionesColanta } from '../lib/opcionesColanta';

//React Router dom
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch } from "react-redux";
import { setEleccion } from '../store/slices/eleccionIndex/index';


//Imagenes
import logoPrincipal from '../images/Globales/logo-flecha.png';
import logoColanta from '../images/Colanta/Menu principal/logo colanta png.png'



const ColantaMenu = () => {

    const navegar = useNavigate();
    const dispatch = useDispatch();


    //Establecemos una funcion que toma el nombre de la seccion a la que se accedio y la establece en el store al igual que con el navegar nos envia a esa pantalla con la seccion electa.
    const irSeccion = (link, seccion) => {
        navegar(link);
        dispatch(setEleccion(
            seccion
        ))
    };

    //Funcion para ir al index de la pagina
    const irIndex = () => {
        navegar('/');
    }

    //Creamos un map de botones que sera la seccion del index en donde daremos para ir a esa seccion y que ejecutara la funcion ehecha previamente.
    let opcionesMenuColanta = opcionesColanta.map(opcion => {
        return <button className='index__button--index' onClick={() => irSeccion(opcion.link, opcion.seccion)}><img src={opcion.img} alt={opcion.nombre}/></button>
    });



    return (
        <>
            <div onClick={() => irIndex()} className="colanta-menu__div--container-alta">
            <div className='colanta-menu__div--container-logo-fixed'>
                    <div className='colanta-menu__div--logo-container'>
                        <img loading='lazy' src={logoPrincipal} alt='logo'/>
                        <img loading='lazy' src={logoColanta} alt='logo-colanta'/>
                    </div>
                    <span className='colanta-menu__span--adorno-linea-blanca1'></span>
                </div>
            </div>
            <div className="colanta-menu__div--container-baja">
                <div className='colanta-menu__div--container-opciones'>
                    {opcionesMenuColanta}
                    <span className='colanta-menu__span--adorno-linea-blanca2'></span>
                </div>
            </div>
        </>

    )
}

export default ColantaMenu;