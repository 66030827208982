import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEleccion } from '../store/slices/eleccionIndex';
import { opciones } from '../lib/opcionesIndex';

//Imagenes
import logoPrincipal from '../images/Globales/Menu Principal-08.png';
import facebook from '../images/Globales/facebook.png';
import instagram from '../images/Globales/instagram.png';
import web from '../images/Globales/web.png';

const MenuLateral = () => {
    const [ isChecked, setIsChecked ] = useState(false);
    const [ despliegueMenu, setDespliegueMenu ] = useState("menu-lateral--container-menu");

    //Funcion que comprueba si le dimos click al boton.
    const handleCheck = (event) => {
        setIsChecked(event.target.checked);
        // console.log("Presionando check", event.target.checked)
        // if(event.target.checked){
        //     setDespliegueMenu("menu-lateral--container-menu-desplegado");
        // }else{
        //     setDespliegueMenu("menu-lateral--container-menu");
        // }
    }

    const navegar = useNavigate();
    const dispatch = useDispatch();


    //Establecemos una funcion que toma el nombre de la seccion a la que se accedio y la establece en el store al igual que con el navegar nos envia a esa pantalla con la seccion electa.
    const irSeccion = (link, seccion) => {
        navegar(link);
        dispatch(setEleccion(
            seccion
        ))
        setIsChecked(false);
    };

    //Creamos un map de botones que sera la seccion del index en donde daremos para ir a esa seccion y que ejecutara la funcion ehecha previamente.
    let opcionesIndex = opciones.map(opcion => {
        return <button className='menu-lateral__button--boton' onClick={() => irSeccion(opcion.link, opcion.seccion)}>{opcion.nombre}</button>
    })


    const entrarWeb = () => {
        window.open(`https://lacteosbombona.com/`, '_blank');
    }

    const entrarWhatsapp = (numero) => {
        window.open(`https://wa.me/${numero}`, '_blank');
    }

    return (
        <div>
            <input type="checkbox" id="btn_menu" checked={isChecked} onChange={handleCheck}/>
            <label for="btn_menu" className="btn_menu flex_column">
                <span id="btn_span_1" className="btn_span"></span>
                <span id="btn_span_2" className="btn_span"></span>
                <span id="btn_span_3" className="btn_span"></span>
            </label>
            <div onClick={() => setIsChecked(false)} className={`menu-lateral--container-menu ${despliegueMenu}`}>
                <div className='menu-lateral__div--contenido-menu'>
                    <div className='menu-lateral__div--contenedor-logo-principal'>
                        <img src={logoPrincipal} alt='logo-lacteos-bombona'/>
                    </div>
                    <div className='menu-lateral__div--contenedor-opciones'>
                        {opcionesIndex}
                    </div>
                    <div className='menu-lateral__div--contenedor-contactanos'>
                        <button onClick={() => entrarWhatsapp("573216419811")}>Contactanos</button>
                    </div>
                    <div className='menu-lateral__div--contenedor-redes'>
                        {/* <img src={facebook} alt='facebook'/>
                        <img src={instagram} alt='instagram'/> */}
                        <img onClick={() => entrarWeb()} src={web} alt='web'/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MenuLateral