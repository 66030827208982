import React from 'react';

//Libreria
import { opciones } from '../lib/opcionesIndex';

//Librerias
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//Store
import { setEleccion } from '../store/slices/eleccionIndex';


//Imagenes
import web from '../images/Globales/web-blanco.png';
import diseñador from '../images/Footer/logo-mauricio.jpg';
import programador from '../images/Footer/logo-xteven.jpg';

const Footer = () => {

    const navegar = useNavigate();
    const dispatch = useDispatch();

    //Establecemos una funcion que toma el nombre de la seccion a la que se accedio y la establece en el store al igual que con el navegar nos envia a esa pantalla con la seccion electa.
    const irSeccion = (link, seccion) => {
        navegar(link);
        dispatch(setEleccion(
            seccion
        ))
    };

    //Creamos un map de botones que sera la seccion del index en donde daremos para ir a esa seccion y que ejecutara la funcion ehecha previamente.
    let opcionesIndex = opciones.map(opcion => {
        return <button onClick={() => irSeccion(opcion.link, opcion.seccion)}>{opcion.nombre}</button>
    });


    const entrarWhatsapp = (numero) => {
        window.open(`https://wa.me/${numero}`, '_blank');
    }

    const entrarWeb = () => {
        window.open(`https://lacteosbombona.com/`, '_blank');
    }

    return (
        <div className='footer__div--container'>
            <div className='footer__div--container-seccion1'>
                <div className='footer__div--container-subseccion footer__div--container-subseccion1'>
                    <button onClick={() => entrarWhatsapp("573216419811")}>Danos tu opinión</button>
                </div>
                <div className='footer__div--container-subseccion footer__div--container-subseccion2'>
                    <h2>Categorías</h2>
                    <div className='footer__div--opciones-menu'>
                        {opcionesIndex}
                    </div>
                </div>
                <div className='footer__div--container-nombre'>
                    <h3>Lácteos Bomboná ©</h3>
                </div>
                <div className='footer__div--container-subseccion footer__div--container-subseccion3'>
                    <h2>Visita nuestra página web</h2>
                    <div className='footer__div--container-redes-sociales'>
                        {/* <button><img src={facebook} alt='facebook'/></button>
                        <button><img src={instagram} alt='instagram'/></button> */}
                        <button onClick={() => entrarWeb()}><img src={web} alt='web'/></button>
                    </div>
                </div>
            </div>
            <div className='footer__div--container-seccion2'>
                <div className='footer__div--linea footer__div--linea-alta'></div>
                <div className='footer__div--linea footer__div--linea-media-izq'></div>
                <div className='footer__div--linea footer__div--linea-media-der'></div>
                <div className='footer__div--linea footer__div--linea-baja'></div>
                <div className='footer__div--container--logos'>
                    <div className='footer__div--container-disenador'>
                        <p>Diseñador:</p>
                        <img onClick={() => entrarWhatsapp("573052272725")} src={diseñador} alt='Mauricio-sanchez'/>
                    </div>
                    <div className='footer__div--container-programador'>
                        <p>Programador:</p>
                        <img onClick={() => entrarWhatsapp("573007162596")} src={programador} alt='Xtevens210'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
