import { createSlice } from "@reduxjs/toolkit";

export const buscadorActivoSlice = createSlice({
    name: 'buscadorActivo',
    initialState: {
        buscadorActivo: false
    },
    reducers: {
        setBuscadorActivo: (state, action) => {
            state.buscadorActivo = action.payload
        }
    }
});

export default buscadorActivoSlice.reducer;

export const { setBuscadorActivo } = buscadorActivoSlice.actions;