import React, { useEffect, useState } from 'react';

//Componentes
import Buscador from './Buscador';

//Imagenes
import homeIcono from '../images/Globales/casa.png';

const Menu = (props) => {
    const [ seccionActual, setSeccionActual ] = useState(false);
    const [ claseMenu, setClaseMenu ] = useState("");
    const [ estiloCasillaElegida, setEstiloCasillaElegida ] = useState("");

    const { seccionElegida, setMostrarProductos, eleccion} = props;

    useEffect(() => {
        const handleScroll = () => {
        const sections = document.querySelectorAll(".productos__div--container-categoria");

        // Obtén la posición de desplazamiento vertical
        const scrollPosition = window.scrollY;

        // Itera sobre cada sección y verifica si está visible en la ventana
        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            const sectionTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            // Verifica si la sección está visible en la ventana
            if (
            scrollPosition >= sectionTop &&
            scrollPosition < sectionTop + sectionHeight
            ) {
            // console.log("Estás en la sección: " + section.id);
            //Establecemos la seccion
            setSeccionActual(section.id);
            break;
            }
        }
        };

        // Agrega un evento de desplazamiento para verificar la posición actual
        window.addEventListener("scroll", handleScroll);

        // Limpia el evento de desplazamiento al desmontar el componente
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if(eleccion === "quesos" || eleccion === "lecheEnPolvo" || eleccion === "lecheUHT" || eleccion === "untablesMantequillas" || eleccion === "cremaDeLeche" || eleccion === "yogures" || eleccion === "avena" || eleccion === "tampico" || eleccion === "kids" || eleccion === "arequipesLecheCondensada" || eleccion === "abarrotes" || eleccion === "carnicos" || eleccion === "productosInstitucionales" || eleccion === "prolinco"){
            setEstiloCasillaElegida("#87C61F");
        }else if(eleccion === "konfyt"){
            setEstiloCasillaElegida("#1500b5");
        }else if(eleccion === "yupi"){
            setEstiloCasillaElegida("#f30000");
        }else if(eleccion === "aseo"){
            setEstiloCasillaElegida("#00C6FD");
        }
    }, []);


    //crear las opciones del menu segun las secciones.
    let opcionesCreadas = seccionElegida.contenido.map(seccion => {
        return (<a href={`#${seccion.nombre}`} style={{backgroundColor: seccionActual === seccion.nombre ? (estiloCasillaElegida):("white"), fontWeight: seccionActual === seccion.nombre ? ("600"):("400"), color: seccionActual === seccion.nombre ? ("white"):("#313131")}}>{seccion.nombre}</a>)
    });



    //Despues de generado el aaray con las opciones del menu vamos a añadir en el array el boton de devolverse a las secciones.
    opcionesCreadas.splice(0,0, (<button className='menu__button--home' onClick={() => setMostrarProductos(false)}><img src={homeIcono} alt='home'/></button>));

    opcionesCreadas.splice(0,0, (<Buscador seccionElegida={seccionElegida}/>));

    // console.log("Opciones creadas",opcionesCreadas)

    // console.log("MostrarProductos", mostrarProductos);

    //Creamos una evento que captura el movimiento de el scroll para posicionar el menu en fixed o en otra posicion en pantalla.
    window.addEventListener('scroll', function() {
        const posicionInicial = 150;

        if(Math.round(window.scrollY ) > posicionInicial){
            setClaseMenu("menu__fixed");
        }else{
            setClaseMenu("");
        }
    });



    return (
        <div className={`menu-prueba-1 ${claseMenu}`} id='menu'>
            <div className='menu-prueba-2'>
                {opcionesCreadas}
            </div>
        </div>
    )
};

export default Menu;