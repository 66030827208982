import React, { useEffect, useState } from 'react';

//Imagenes adornos
//Leche en polvo
import lecheEnPolvoAdorno1 from '../images/Adornos/leche-en-polvo/leche-en-polvo-adorno-1.png';

//Leche UHT
import lecheUHTAdorno1 from '../images/Adornos/LecheUHT/leche-uht-adorno1.png';
import lecheUHTAdorno2 from '../images/Adornos/LecheUHT/leche-uht-adorno2.png';
import lecheUHTAdorno3 from '../images/Adornos/LecheUHT/leche-uht-adorno3.png';


//Cuñas y varios
import cunasVariosAdorno1 from '../images/Adornos/Quesos/cunas-varios-adornos/cunas-varios-dorno1.png';

//Parmesano
import parmesanoAdorno1 from '../images/Adornos/Quesos/parmesano-adornos/parmesano-adorno.png';

//Mozzarella
import mozzarellaAdorno1 from '../images/Adornos/Quesos/mozzarella-adornos/mozzarella-adorno.png';

//Crema de leche
import cremaDeLecheAdorno1 from '../images/Adornos/Crema-de-leche/crema-de-leche-adorno1.png';
import cremaDeLecheAdorno2 from '../images/Adornos/Crema-de-leche/crema-de-leche-adorno2.png';

//Madurados
import quesoMaduradoAdorno1 from '../images/Adornos/Quesos/madurados/madurados-adorno1.png';
import quesoMaduradoAdorno2 from '../images/Adornos/Quesos/madurados/madurados-adorno3.png';

//Untables y mantequillas
import untablesAdorno1 from '../images/Adornos/Untables y cremas/untables-adorno1.png';
import untablesAdorno2 from '../images/Adornos/Untables y cremas/untables-adorno2.png';
import untablesAdorno3 from '../images/Adornos/Untables y cremas/untables-adorno3.png';
import untablesAdorno4 from '../images/Adornos/Untables y cremas/untables-adorno4.png';

//Avena
import avenaAdorno1 from '../images/Adornos/Avena/avena-adorno1.png';

const FondoIlustrado = (props) => {

    const [ fondoIlustrado, setFondoIlustrado ] = useState("");

    const { eleccion  } = props;

    //Fondos
    //Creamos un if que pone un fondo en la parte de productos segun la categoria
    useEffect(() => {
        // if(eleccion === "Leche en polvo"){
        //     setFondoIlustrado(
        //         <>
        //             <img className='fondoIlustrado__img--adorno--leche-en-polvo' src={lecheEnPolvoAdorno1} alt='adorno'/>
        //         </>
        //     );
        // }else
        if(eleccion === "Leche UHT"){
            setFondoIlustrado(
                <>
                    <img loading='lazy' className='fondoIlustrado__img--adorno-lecheUHT fondoIlustrado__img--lecheUHT-adorno1' src={lecheUHTAdorno1} alt='adorno'/>
                    <img loading='lazy' className='fondoIlustrado__img--adorno-lecheUHT fondoIlustrado__img--lecheUHT-adorno2' src={lecheUHTAdorno2} alt='adorno'/>
                    <img loading='lazy' className='fondoIlustrado__img--adorno-lecheUHT fondoIlustrado__img--lecheUHT-adorno3' src={lecheUHTAdorno3} alt='adorno'/>
                </>
            );
        // }else if(eleccion === "Cuñas y varios"){
        //     setFondoIlustrado(
        //         <>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-cunas-varios fondoIlustrado__img--cunas-varios-adorno1' src={cunasVariosAdorno1} alt='adorno'/>
        //         </>
        //     );
        // }else if(eleccion === "Queso parmesano"){
        //     setFondoIlustrado(
        //         <>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-parmesano fondoIlustrado__img--parmesano-adorno1' src={parmesanoAdorno1} alt='adorno'/>
        //         </>
        //     );
        // }else if(eleccion === "Queso mozzarella"){
        //     setFondoIlustrado(
        //         <>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-mozzarella fondoIlustrado__img--mozzarella-adorno1' src={mozzarellaAdorno1} alt='adorno'/>
        //         </>
        //     );
        // }else
        }else if(eleccion === "Crema de leche"){
            setFondoIlustrado(
                <>
                    <img loading='lazy' className='fondoIlustrado__img--adorno-crema-de-leche fondoIlustrado__img--crema-de-leche-adorno1' src={cremaDeLecheAdorno1} alt='adorno'/>
                    <img loading='lazy' className='fondoIlustrado__img--adorno-crema-de-leche fondoIlustrado__img--crema-de-leche-adorno2' src={cremaDeLecheAdorno2} alt='adorno'/>
                </>
            );
        // }else if(eleccion === "Quesos madurados"){
        //     setFondoIlustrado(
        //         <>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-quesos-madurados fondoIlustrado__img--quesos-madurados-adorno1' src={quesoMaduradoAdorno1} alt='adorno'/>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-quesos-madurados fondoIlustrado__img--quesos-madurados-adorno2' src={quesoMaduradoAdorno2} alt='adorno'/>
        //         </>
        //     );
        // }else if(eleccion === "Untables y mantequillas"){
        //     setFondoIlustrado(
        //         <>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-untables fondoIlustrado__img--untables-adorno1' src={untablesAdorno1} alt='adorno'/>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-untables fondoIlustrado__img--untables-adorno4' src={untablesAdorno3} alt='adorno'/>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-untables fondoIlustrado__img--untables-adorno2' src={untablesAdorno2} alt='adorno'/>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-untables fondoIlustrado__img--untables-adorno3' src={untablesAdorno4} alt='adorno'/>
        //         </>
        //     );
        // }else if(eleccion === "Avena"){
        //     setFondoIlustrado(
        //         <>
        //             <img loading='lazy' className='fondoIlustrado__img--adorno-avena fondoIlustrado__img--avena-adorno1' src={avenaAdorno1} alt='adorno'/>
        //         </>
        //     );
        }else{
            setFondoIlustrado(
                <>
                </>
            );
        }
    }, [eleccion])
    return (
        <>
            {fondoIlustrado}
        </>
    )
}

export default FondoIlustrado