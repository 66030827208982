

//Imagenes seccion

//_____________________________________________________________________
//Abarrotes
import kelloggs from '../images/Secciones/Abarrotes/kelloggs.png';
import copelia from '../images/Secciones/Abarrotes/copelia.png';
import aburra from '../images/Secciones/Abarrotes/aburra.png';
import saviloe from '../images/Secciones/Abarrotes/saviloe.png';
import bamby from '../images/Secciones/Abarrotes/bamby.png';
import elTrapiche from '../images/Secciones/Abarrotes/eltrapiche.png';
import pompeya from '../images/Secciones/Abarrotes/pompeya.png';
import bary from '../images/Secciones/Abarrotes/bary.png';
import aderezos from '../images/Secciones/Abarrotes/aderezos.png';
import oreo from '../images/Secciones/Abarrotes/oreo.png';
import festival from '../images/Secciones/Abarrotes/Festival.png';
import clubSocial from '../images/Secciones/Abarrotes/club-social.png';
// import sinAzucar from '../images/Secciones/Abarrotes/sin azucar.png';
import colanta from '../images/Secciones/Abarrotes/colanta.png';


//_____________________________________________________________________
//Konfyt
import gelatinas from '../images/Secciones/Konfyt/gelatinas.png';
import mermeladas from '../images/Secciones/Konfyt/mermeladas.png';
import dulces from '../images/Secciones/Konfyt/dulces.png';
import natilla from '../images/Secciones/Konfyt/natilla.png';
import sucrasola from '../images/Secciones/Konfyt/sucrasola.png';
import sinAzucarAnadida from '../images/Secciones/Konfyt/sin-azucar-anadido.png';



//_____________________________________________________________________
//aseo
import gutVan from '../images/Secciones/Aseo/gutvan.png';
import nube from '../images/Secciones/Aseo/nube.png';
import fassi from '../images/Secciones/Aseo/FASSI.png';
import incauca from '../images/Secciones/Aseo/incauca.png';
import membersSelection from '../images/Secciones/Aseo/members selection.png';

//_____________________________________________________________________
//yupi
import loncheras from '../images/Secciones/yupi/loncheras.png';
import rizadas from '../images/Secciones/yupi/rizadas.png';
import golpeDeTodo from '../images/Secciones/yupi/golpe con todo.png';
import tosti from '../images/Secciones/yupi/tosti.png';
import palomitas from '../images/Secciones/yupi/palomitas.png';
import yupis from '../images/Secciones/yupi/yupis.png';
import clasicas from '../images/Secciones/yupi/clasicas.png';
import mekato from '../images/Secciones/yupi/mekato.png';
import varios from '../images/Secciones/yupi/varios.png';

//_____________________________________________________________________
//_____________________________________________________________________
//Productos colanta

//Leche en polvo
import lecheEnPolvoColanta from '../images/Colanta/Leche en polvo/leche-en-polvo.png';
import lecheEnPolvoMontefrio from '../images/Colanta/Leche en polvo montefrio/leche en polvo montefrio.png';

//Leche UHT
import lecheUHT from '../images/Colanta/Leche UHT/leche-uht.png';


//Quesos
import quesitosCuajadas from '../images/Colanta/Quesos/quesitos-cuajadas.png.png';
import quesoColanta from '../images/Colanta/Quesos/queso-colanta.png.png';
import quesoMozarella from '../images/Colanta/Quesos/queso-mozarella.png.png';
import quesoDobleCrema from '../images/Colanta/Quesos/queso-doblecrema.png.png';
import quesosMadurados from '../images/Colanta/Quesos/quesos-madurados.png.png';
import quesoParmesano from '../images/Colanta/Quesos/queso-parmesano.png.png';
import cunasVarios from '../images/Colanta/Quesos/cunas-varios.png';


//Untables y mantequilla
import untablesMantequillas from '../images/Colanta/Untables y mantequillas/untables-mantequillas.png';

//Crema de leche
import cremaDeLeche from '../images/Colanta/Crema de leche/crema-de-leche.png';

//Productos Institucionales
import lecheEnPolvo from '../images/Colanta/Productos institucionales/leche-en-polvo.png';
import arequipes from '../images/Colanta/Productos institucionales/arequipes.png';
import cremas from '../images/Colanta/Productos institucionales/cremas.png';
import mantequillaPasteurizada from '../images/Colanta/Productos institucionales/mantequilla-pasteurizada.png';
import quesoInstitucional from '../images/Colanta/Productos institucionales/queso-institucional.png';

//Yogures
import yogurtCereal from '../images/Colanta/Yogures/yogurt-cereal.png';
import yogurt from '../images/Colanta/Yogures/yogurt.png';
import kumis from '../images/Colanta/Yogures/kumis.png';
import yogurtColanta from '../images/Colanta/Yogures/yogurt-colanta.png';
import yogurtVida from '../images/Colanta/Yogures/yogurt-vida.png';
import yogurtBufala from '../images/Colanta/Yogures/yogurt-bufala.png';
import yogurtSligth from '../images/Colanta/Yogures/yogurt-sligth.png';
import paquetesYogures from '../images/Colanta/Yogures/paquete-yogures.png';
import yogurtGriego from '../images/Colanta/Yogures/yogurt-griego.png';
import yogurtBenecol from '../images/Colanta/Yogures/benecol.png';


//Kids
import kids from '../images/Colanta/Kids/kids.png';


//Arequipes y leche condensada
import arequipesLecheCondensada from '../images/Colanta/Arequipes y leche condensada/arequipes-leche-condensada.png';

//Carnicos
import carnesFrias from '../images/Colanta/Carnicos/carnes-frias.png';
import carnicosInstitucionales from '../images/Colanta/Carnicos/carnicos-institucionales.png';
import carneAlVacio from '../images/Colanta/Carnicos/carne-al-vacio.png';

//Tampico
import tampico from '../images/Colanta/Tampico/tampico.png';

//Avena
import avenas from '../images/Colanta/Avena/avena.png';

//Prolinco
import prolinco from '../images/Colanta/Prolinco/prolinco.png';

//Ejemplos

//Faltante
// import faltante from '../images/Secciones/Abarrotes/Faltante.png';

//Productos listas
import {  quesoDobleCremaProductos, quesitosCuajadasProductos, quesoMozzarellaProductos, quesoColantaProdcutos, lecheEnPolvoProductos, lecheUHTProductos, quesosMaduradosProductos, quesoParmesanoProductos, quesosCunasYvariosProductos, untablesMantequillasProductos, cremaDeLecheProductos, yogurtBufalaProductos, yogurtConCerealProductos, yogurtGriegoProductos, yogurtVidaProductos, avenaProductos, kidsProductos, arequipesLechesCondensadasProductos, paquetesYogurtProductos, kelloggsProductos, copeliaProductos, aburraProductos, bambyProductos, trapicheProductos, pompeyaProductos, baryProductos, aderezosProductos, oreoToshProductos, festivalProductos, clubSocialProductos, sucralosaProductos, gelatinasProductos, natillasPancakesProductos, mermeladasProductos, dulcesProductos, saviloeVive100Productos, loncherasProductos, rizadasProductos, palomitasTocinetasProductos, sinAzucarAnadidoProductos, carnesFriasProductos, carnicosInstitucionalesProductos, colantaAbarrotesProductos, tampicoProductos, yogurtBenecolProductos, yogurtSligthProductos, yogurtColantaProductos, kumisProductos, yagurProductos, lecheEnPolvoInstitucionalProductos, arequipesInstitucionalProductos, cremaDeLecheInstitucionalProductos, quesoInstitucionalProductos, gutvanProductos, nubeProductos, fassiProductos, incaucaProductos, carneAlVacioProductos, mantequillaPasteurizadaProductos, prolincoProductos, golpeProductos, tostiProductos, yupisProductos, membersSelectionProductos, lecheEnPolvoMontefrioProductos, clasicasProductos, mekatoProductos, variosYupiProductos, } from './productos';



let secciones = {
    abarrotes:{
        contenido: [
            {
                nombre: "Kellogg's",
                img: kelloggs,
                productos: kelloggsProductos
            },
            {
                nombre: "Copelia",
                img: copelia,
                productos: copeliaProductos
            },
            {
                nombre: "Aburrá",
                img: aburra,
                productos: aburraProductos
            },
            {
                nombre: "Saviloe Vive100",
                img: saviloe,
                productos: saviloeVive100Productos
            },
            {
                nombre: "Bamby",
                img: bamby,
                productos: bambyProductos
            },
            {
                nombre: "El trapiche",
                img: elTrapiche,
                productos: trapicheProductos
            },
            {
                nombre: "Pompeya",
                img: pompeya,
                productos: pompeyaProductos
            },
            {
                nombre: "Bary",
                img: bary,
                productos: baryProductos
            },
            {
                nombre: "Aderezos",
                img: aderezos,
                productos: aderezosProductos
            },
            {
                nombre: "Oreo Tosh",
                img: oreo,
                productos: oreoToshProductos
            },
            {
                nombre: "Festival",
                img: festival,
                productos: festivalProductos
            },
            {
                nombre: "Club social",
                img: clubSocial,
                productos: clubSocialProductos
            },
            {
                nombre: "Colanta",
                img: colanta,
                productos: colantaAbarrotesProductos
            },
        ]
    },
    konfyt:{
        contenido: [
            {
                nombre: "Gelatinas",
                img: gelatinas,
                productos: gelatinasProductos
            },
            {
                nombre: "Mermeladas",
                img: mermeladas,
                productos: mermeladasProductos
            },
            {
                nombre: "Dulces",
                img: dulces,
                productos: dulcesProductos
            },
            {
                nombre: "Natillas y pancakes",
                img: natilla,
                productos: natillasPancakesProductos
            },
            {
                nombre: "Sucralosa",
                img: sucrasola,
                productos: sucralosaProductos
            },
            {
                nombre: "Sin azúcar añadida",
                img: sinAzucarAnadida,
                productos: sinAzucarAnadidoProductos
            }
        ]
    },
    aseo:{
        contenido: [
            {
                nombre: "GutVan",
                img: gutVan,
                productos: gutvanProductos
            },
            {
                nombre: "Nube",
                img: nube,
                productos: nubeProductos
            },
            {
                nombre: "Fassi",
                img: fassi,
                productos: fassiProductos
            },
            {
                nombre: "Incauca",
                img: incauca,
                productos: incaucaProductos
            },
            {
                nombre: "Members's Selection",
                img: membersSelection,
                productos: membersSelectionProductos
            }
        ]
    },
    yupi:{
        contenido: [
            {
                nombre: "Loncheras",
                img: loncheras,
                productos: loncherasProductos
            },
            {
                nombre: "Rizadas",
                img: rizadas,
                productos: rizadasProductos
            },
            {
                nombre: "Golpe con todo",
                img: golpeDeTodo,
                productos: golpeProductos
            },
            {
                nombre: "Tosti",
                img: tosti,
                productos: tostiProductos
            },
            {
                nombre: "Palomitas y Tozinetas",
                img: palomitas,
                productos: palomitasTocinetasProductos
            },
            {
                nombre: "Yupis",
                img: yupis,
                productos: yupisProductos
            },
            {
                nombre: "Clásicas",
                img: clasicas,
                productos: clasicasProductos
            },
            {
                nombre: "Mekato",
                img: mekato,
                productos: mekatoProductos
            },
            {
                nombre: "Varios",
                img: varios,
                productos: variosYupiProductos
            }
        ]
    },//Productos colanta
    lecheEnPolvo:{
        contenido: [
            {
                nombre: "Leche en polvo",
                img: lecheEnPolvoColanta,
                productos: lecheEnPolvoProductos
            },
            {
                nombre: "Leche en polvo montefrío",
                img: lecheEnPolvoMontefrio,
                productos: lecheEnPolvoMontefrioProductos
            }
        ]
    },
    lecheUHT:{
        contenido: [
            {
                nombre: "Leche UHT",
                img: lecheUHT,
                productos: lecheUHTProductos
            }
        ]
    },
    quesos:{
        contenido: [
            {
                nombre: "Quesitos y cuajadas",
                img: quesitosCuajadas,
                productos: quesitosCuajadasProductos
            },
            {
                nombre: "Queso colanta",
                img: quesoColanta,
                productos: quesoColantaProdcutos
            },
            {
                nombre: "Queso mozzarella",
                img: quesoMozarella,
                productos: quesoMozzarellaProductos
            },
            {
                nombre: "Queso doblecrema",
                img: quesoDobleCrema,
                productos: quesoDobleCremaProductos
            },
            {
                nombre: "Quesos madurados",
                img: quesosMadurados,
                productos: quesosMaduradosProductos
            },
            {
                nombre: "Queso parmesano",
                img: quesoParmesano,
                productos: quesoParmesanoProductos
            },
            {
                nombre: "Cuñas y varios",
                img: cunasVarios,
                productos: quesosCunasYvariosProductos
            }
        ]
    },
    untablesMantequillas:{
        contenido: [
            {
                nombre: "Untables y mantequillas",
                img: untablesMantequillas,
                productos: untablesMantequillasProductos
            }
        ]
    },
    cremaDeLeche:{
        contenido: [
            {
                nombre: "Crema de leche",
                img: cremaDeLeche,
                productos: cremaDeLecheProductos
            }
        ]
    },
    productosInstitucionales:{
        contenido: [
            {
                nombre: "Leche en polvo institucional",
                img: lecheEnPolvo,
                productos: lecheEnPolvoInstitucionalProductos
            },
            {
                nombre: "Arequipes institucional",
                img: arequipes,
                productos: arequipesInstitucionalProductos
            },
            {
                nombre: "Crema de leche institucional",
                img: cremas,
                productos: cremaDeLecheInstitucionalProductos
            },
            {
                nombre: "Mantequilla pasteurizada",
                img: mantequillaPasteurizada,
                productos: mantequillaPasteurizadaProductos
            },
            {
                nombre: "Queso institucional",
                img: quesoInstitucional,
                productos: quesoInstitucionalProductos
            }
        ]
    },
    yogures:{
        contenido: [
            {
                nombre: "Yogur con cereal",
                img: yogurtCereal,
                productos: yogurtConCerealProductos
            },
            {
                nombre: "Yagur",
                img: yogurt,
                productos: yagurProductos
            },
            {
                nombre: "Kumis",
                img: kumis,
                productos: kumisProductos
            },
            {
                nombre: "Yogur colanta",
                img: yogurtColanta,
                productos: yogurtColantaProductos
            },
            {
                nombre: "Yogur vida",
                img: yogurtVida,
                productos: yogurtVidaProductos
            },
            {
                nombre: "Yogur de bufala",
                img: yogurtBufala,
                productos: yogurtBufalaProductos
            },
            {
                nombre: "Yogur slight",
                img: yogurtSligth,
                productos: yogurtSligthProductos
            },
            {
                nombre: "Paquetes de yogur",
                img: paquetesYogures,
                productos: paquetesYogurtProductos
            },
            {
                nombre: "Yogur griego",
                img: yogurtGriego,
                productos: yogurtGriegoProductos
            },
            {
                nombre: "Yogur benecol",
                img: yogurtBenecol,
                productos: yogurtBenecolProductos
            }
        ]
    },
    kids:{
        contenido: [
            {
                nombre: "Kids",
                img: kids,
                productos: kidsProductos
            },
        ]
    },
    arequipesLecheCondensada:{
        contenido: [
            {
                nombre: "Arequipes y leche condensada",
                img: arequipesLecheCondensada,
                productos: arequipesLechesCondensadasProductos
            },
        ]
    },
    carnicos:{
        contenido: [
            {
                nombre: "Carnes frias",
                img: carnesFrias,
                productos: carnesFriasProductos
            },
            {
                nombre: "Carnicos institucionales",
                img: carnicosInstitucionales,
                productos: carnicosInstitucionalesProductos
            },
            {
                nombre: "Carne al vacio",
                img: carneAlVacio,
                productos: carneAlVacioProductos
            },
        ]
    },
    tampico:{
        contenido: [
            {
                nombre: "Tampico",
                img: tampico,
                productos: tampicoProductos
            },
        ]
    },
    avena:{
        contenido: [
            {
                nombre: "Avena",
                img: avenas,
                productos: avenaProductos
            },
        ]
    },
    prolinco:{
        contenido: [
            {
                nombre: "Prolinco",
                img: prolinco,
                productos: prolincoProductos
            },
        ]
    },
};

export { secciones };