import { Navigate } from 'react-router-dom';
import SeccionProductos from '../pages/SeccionProductos';
import { useSelector } from 'react-redux';

export const RedireccionSeccion = () => {
    let eleccion = useSelector(state => state.eleccionIndex.eleccion);

    if(!eleccion) {
        return <Navigate to="/"/>
    }else{
        return <SeccionProductos/>
    }
};