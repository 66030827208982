import React from 'react';

//Imagenes
import cerrar from '../images/Globales/cerrar.png';

const ProductoElegido = (props) => {

    const { producto, setMostrarProducto } = props;

    // console.log("productoX", producto);

    return (
        <div className='producto-elegido__div--container'>
            <div className='producto-elegido__div--tarjeta'>
                <button onClick={() => setMostrarProducto(false)}><img src={cerrar} alt='cerrar'/></button>
                <img loading='lazy' width="100px" src={producto.img} alt='img'/>
            </div>
        </div>
    )
};

export default ProductoElegido;