import { createSlice } from "@reduxjs/toolkit";

export const datosEncontradosSlice = createSlice({
    name: 'datosEncontrados',
    initialState: {
        datosEncontrados: []
    },
    reducers: {
        setDatosEncontrados: (state, action) => {
            state.datosEncontrados = action.payload
        }
    }
});

export default datosEncontradosSlice.reducer;

export const { setDatosEncontrados } = datosEncontradosSlice.actions;