import React, { useState } from 'react';
import ProductoElegido from './ProductoElegido';

const Producto = (props) => {

    const [ mostrarProducto, setMostrarProducto ] = useState(false);

    const  { producto } = props;

    if(mostrarProducto === true){
        document.body.style.overflow = 'hidden';
    }else if(mostrarProducto === false){
        document.body.style.overflow = 'auto';
    }

    // console.log("producto", producto);

    return (
        <>
            <div className='producto__div--container'>
                <div className='producto__div--tarjeta' onClick={() => setMostrarProducto(true)}>
                    <img loading='lazy' src={producto.img} alt='img'/>
                </div>
            </div>
            {mostrarProducto ? (<ProductoElegido setMostrarProducto={setMostrarProducto} producto={producto}/>):("")}
        </>

    )
}

export default Producto;