
import { BrowserRouter, Routes, Route } from 'react-router-dom';


//Paginas
import Index from './pages/Index';
import { Provider } from 'react-redux';
import store from './store';
import { RedireccionSeccion } from './components/RedireccionSeccion';
import ColantaMenu from './pages/ColantaMenu';



function App() {


  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Index/>}/>
          <Route path='/seccion' element={<RedireccionSeccion/>}/>
          <Route path='/individual' element={<RedireccionSeccion/>}/>
          <Route path='/colanta' element={<ColantaMenu/>}/>
          <Route path='*' element={<Index/>}/>
        </Routes>
      </BrowserRouter>
    </Provider>

  );
}

export default App;
