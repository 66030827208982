//Imagenes
import lecheEnPolvo from '../images/Colanta/Menu principal/leche-en-polvo.png';
import lecheUHT from '../images/Colanta/Menu principal/leche-uht.png';
import quesos from '../images/Colanta/Menu principal/quesos.png';
import untablesMantequilla from '../images/Colanta/Menu principal/untables-mantequillas.png';
import cremaDeLeche from '../images/Colanta/Menu principal/crema-de-leche.png';
import productosInstitucionales from '../images/Colanta/Menu principal/productos-institucionales.png';
import yogures from '../images/Colanta/Menu principal/yogures.png';
import kids from '../images/Colanta/Menu principal/kids.png';
import arequipeLecheCondensada from '../images/Colanta/Menu principal/arequipes-leche-condensada.png';
import carnicos from '../images/Colanta/Menu principal/carnicos.png';
import tampico from '../images/Colanta/Menu principal/tampico.png';
import avena from '../images/Colanta/Menu principal/avena.png';
import prolinco from '../images/Colanta/Menu principal/prolinco.png';



let opcionesColanta = [
    {
        nombre: "leche en polvo",
        link: "/seccion",
        seccion: "lecheEnPolvo",
        img: lecheEnPolvo
    },
    {
        nombre: "leche UHT",
        link: "/seccion",
        seccion: "lecheUHT",
        img: lecheUHT
    },
    {
        nombre: "Quesos",
        link: "/seccion",
        seccion: "quesos",
        img: quesos
    },
    {
        nombre: "Untables y mantequilla",
        link: "/seccion",
        seccion: "untablesMantequillas",
        img: untablesMantequilla
    },
    {
        nombre: "Crema de leche",
        link: "/seccion",
        seccion: "cremaDeLeche",
        img: cremaDeLeche
    },
    {
        nombre: "Productos institucionales",
        link: "/seccion",
        seccion: "productosInstitucionales",
        img: productosInstitucionales
    },
    {
        nombre: "Yogures",
        link: "/seccion",
        seccion: "yogures",
        img: yogures
    },
    {
        nombre: "Kids",
        link: "/seccion",
        seccion: "kids",
        img: kids
    },
    {
        nombre: "Arequipe y leche condensada",
        link: "/seccion",
        seccion: "arequipesLecheCondensada",
        img: arequipeLecheCondensada
    },
    {
        nombre: "Carnicos",
        link: "/seccion",
        seccion: "carnicos",
        img: carnicos
    },
    {
        nombre: "Tampico",
        link: "/seccion",
        seccion: "tampico",
        img: tampico
    },
    {
        nombre: "Avena",
        link: "/seccion",
        seccion: "avena",
        img: avena
    },
    {
        nombre: "Prolinco",
        link: "/seccion",
        seccion: "prolinco",
        img: prolinco
    }
];

export {opcionesColanta};