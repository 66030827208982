import { createSlice } from "@reduxjs/toolkit";

export const eleccionIndexSlice = createSlice({
    name: 'eleccionIndex',
    initialState: {
        eleccion: false
    },
    reducers: {
        setEleccion: (state, action) => {
            state.eleccion = action.payload
        }
    }
});

export default eleccionIndexSlice.reducer;

export const { setEleccion } = eleccionIndexSlice.actions;