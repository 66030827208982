import React, { useEffect, useState } from 'react';

//Componentes
import Producto from './Producto';
import Menu from './Menu';
import MenuLateral from './MenuLateral';
import Footer from './Footer';

//Redux
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Imagenes
import logoPrincipal from '../images/Globales/logo-flecha.png';
import logoColanta from '../images/Colanta/Menu principal/logo colanta png.png';
import logoKonfyt from '../images/Secciones/Konfyt/Menu Konfyt-10.png'
import logoYupi from '../images/Secciones/yupi/yupi.png';

import FondoIlustrado from './FondoIlustrado';


const Productos = (props) => {
    const [ claseContainer, setClaseContainer ] = useState(false);
    const [ encabezadoComponente, setEncabezadoComponente ] = useState("");
    const { seccionElegida, setMostrarProductos, mostrarProductos, eleccion } = props;

    const navegar = useNavigate();





    // console.log("seccionElegida", seccionElegida);

    let componenteMostrarProductos = seccionElegida.contenido.map(seccion => {
        return (
        <div className='productos__div--container-categoria' id={seccion.nombre}>
            <img loading='lazy' className='productos__img--imagen-categoria' onClick={() => setMostrarProductos(false)} width="400px" src={seccion.img} alt='img'/>
            <div className='productos__div--container-productos'>
                <FondoIlustrado eleccion={seccion.nombre}/>
                {seccion.productos.map(producto => {
                    return (<Producto producto={producto}/>)
                })}
            </div>
        </div>)
    })

    //Creamos una funcion para ir al index
    const irIndex = () => {
        if(eleccion === "quesos" || eleccion === "productosInstitucionales" || eleccion === "yogures" || eleccion === "carnicos" || eleccion === "lecheEnPolvo" || eleccion === "lecheUHT" || eleccion === "untablesMantequillas" || eleccion === "cremaDeLeche" || eleccion === "avena" || eleccion === "arequipesLecheCondensada" || eleccion === "kids" || eleccion === "tampico" || eleccion === "prolinco"){
            navegar('/colanta');
        }else{
            navegar('/');
        }
    }

    //Traemos de la store si el buscador esta activo
    const buscadorActivo = useSelector(state => state.buscadorActivo.buscadorActivo);

    //Traemos de la store los datos encontrados.
    const datosEncontrados = useSelector(state => state.datosEncontrados.datosEncontrados);


    //Vamos a validar el fondo de los productos segun su categoria y vamos a generar el encabezado.
    useEffect(() => {
        if(eleccion === "quesos" || eleccion === "lecheEnPolvo" || eleccion === "lecheUHT" || eleccion === "untablesMantequillas" || eleccion === "cremaDeLeche" || eleccion === "yogures" || eleccion === "avena" || eleccion === "kids" || eleccion === "arequipesLecheCondensada" || eleccion === "tampico" || eleccion === "abarrotes" || eleccion === "carnicos" || eleccion === "productosInstitucionales" || eleccion === "prolinco"){
            setClaseContainer("productos__div--container--colanta");
            setEncabezadoComponente(
                <div onClick={() => irIndex()} className='productos__div--logos-container'>
                    <img loading='lazy' src={logoPrincipal} alt='logo'/>
                    <img loading='lazy' src={logoColanta} alt='logo-colanta'/>
                </div>
            )
        }else if( eleccion === "konfyt"){
            setClaseContainer("productos__div--container--konfyt");
            setEncabezadoComponente(
                <div onClick={() => irIndex()} className='productos__div--logos-container'>
                    <img loading='lazy' src={logoPrincipal} alt='logo'/>
                    <img loading='lazy' src={logoKonfyt} alt='logo-konfyt'/>
                </div>
            )
        }else if( eleccion === "yupi"){
            setClaseContainer("productos__div--container--yupi");
            setEncabezadoComponente(
                <div onClick={() => irIndex()} className='productos__div--logos-container'>
                    <img loading='lazy' src={logoPrincipal} alt='logo'/>
                    <img loading='lazy' src={logoYupi} alt='logo-yupi'/>
                </div>
            )
        }else if( eleccion === "aseo"){
            setClaseContainer("productos__div--container--aseo");
            setEncabezadoComponente(
                <div onClick={() => irIndex()} className='productos__div--logos-container'>
                    <img loading='lazy' src={logoPrincipal} alt='logo'/>
                </div>
            )
        }
    }, [eleccion]);



    return (
        <div className={claseContainer}>
            {encabezadoComponente}
            <Menu eleccion={eleccion} seccionElegida={seccionElegida} setMostrarProductos={setMostrarProductos} mostrarProductos={mostrarProductos}/>
            <div className='productos__div--container-categorias-productos'>
                {/* Si el buscador esta desactivado se renderizan los productos sino se quitara la lista de productos y se mostrara la busqueda que el usuario este haciendo. */}
                {buscadorActivo ? (""):(componenteMostrarProductos)}
                {buscadorActivo ? (
                    <div className='productos__div--productos-encontrados'>
                        {datosEncontrados}
                    </div>
                ):("")}
            </div>
            <MenuLateral/>
            <Footer/>
        </div>
    )
};

export default Productos;