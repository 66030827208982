//Imagenes
import colanta from '../images/Index/colanta.png';
import konfyt from '../images/Index/konfyt.png';
import yupi from '../images/Index/yupi.png';



let opciones = [
    {
        nombre: "Colanta",
        link: "/colanta",
        seccion: false,
        img: colanta
    },
    {
        nombre: "Abarrotes",
        link: "/seccion",
        seccion: "abarrotes",
        img: false
    },
    {
        nombre: "Konfyt",
        link: "/seccion",
        seccion: "konfyt",
        img: konfyt
    },
    {
        nombre: "Aseo y cafeteria",
        link: "/seccion",
        seccion: "aseo",
        img: false
    },
    {
        nombre: "Yupi",
        link: "/seccion",
        seccion: "yupi",
        img: yupi
    }
];

export {opciones};