
//Imagenes de productos

//Colanta
//Leche en polvo
import lecheEnPolvo1 from '../images/Productos/Leche en polvo productos/leche-en-polvo/leche-en-polvo-entera900gr.png';
import lecheEnPolvo2 from '../images/Productos/Leche en polvo productos/leche-en-polvo/leche-en-polvo-entera380gr.png';
import lecheEnPolvo3 from '../images/Productos/Leche en polvo productos/leche-en-polvo/leche-en-polvo-entera200gr.png';
import lecheEnPolvo4 from '../images/Productos/Leche en polvo productos/leche-en-polvo/leche-en-polvo-entera25gr.png';
import lecheEnPolvo6 from '../images/Productos/Leche en polvo productos/leche-en-polvo/leche-en-polvo-semidescremada380gr.png';
import lecheEnPolvo7 from '../images/Productos/Leche en polvo productos/leche-en-polvo/leche-en-polvo-descremada380gr.png';


//Leche en polvo montefrio
import lecheEnPolvoMontefrio1 from '../images/Productos/Leche en polvo montefrio productos/leche-en-polvo/leche-en-polvo-montefrio-entera200ml.png';
import lecheEnPolvoMontefrio2 from '../images/Productos/Leche en polvo montefrio productos/leche-en-polvo/leche-en-polvo-montefrio-entera900gr.png';

//Leche UHT
import lecheUHT1 from '../images/Productos/Leche UHT Productos/leche-entera-uht-bolsa1000ml.png';
import lecheUHT2 from '../images/Productos/Leche UHT Productos/leche-entera-uht-bolsa900ml.png';
import lecheUHT3 from '../images/Productos/Leche UHT Productos/leche-entera-uht-caja1000ml.png';
import lecheUHT4 from '../images/Productos/Leche UHT Productos/leche-entera-uht-bolsa200ml.png';
import lecheUHT5 from '../images/Productos/Leche UHT Productos/leche-entera-uht-caja200ml.png';
import lecheUHT6 from '../images/Productos/Leche UHT Productos/leche-deslactosada-uht-bolsa1000ml.png';
import lecheUHT7 from '../images/Productos/Leche UHT Productos/leche-deslactosada-uht-caja900ml.png';
import lecheUHT8 from '../images/Productos/Leche UHT Productos/leche-montefrio-uht-caja900ml.png';
import lecheUHT9 from '../images/Productos/Leche UHT Productos/leche-deslactosada-uht-caja1000ml.png';
import lecheUHT10 from '../images/Productos/Leche UHT Productos/leche-semidescremada-uht-caja1050ml.png';
import lecheUHT11 from '../images/Productos/Leche UHT Productos/leche-descremada-uht-caja1050ml.png';
import lecheUHT12 from '../images/Productos/Leche UHT Productos/leche-slight-uht-caja1080ml.png';
import lecheUHT13 from '../images/Productos/Leche UHT Productos/leche-deslactosada-semidescremada-uht-bolsa400ml.png';
import lecheUHT14 from '../images/Productos/Leche UHT Productos/leche-entera-uht-bolsa400ml.png';

//Quesos
//Quesitos y cuajadas
import quesitosCuajadas1 from '../images/Productos/Quesos Productos/quesitos-cuajadas/quesito-montefrio380gr.png';
import quesitosCuajadas2 from '../images/Productos/Quesos Productos/quesitos-cuajadas/quesito-montefrio200gr.png';
import quesitosCuajadas3 from '../images/Productos/Quesos Productos/quesitos-cuajadas/cuajada-colanta400gr.png';
import quesitosCuajadas4 from '../images/Productos/Quesos Productos/quesitos-cuajadas/cuajada-colanta500gr.png';
import quesitosCuajadas5 from '../images/Productos/Quesos Productos/quesitos-cuajadas/cuajada-colanta250gr.png';
// import quesitosCuajadas6 from '../images/Productos/Quesos Productos/quesitos-cuajadas/queso-costeno-colanta1kg.png';

//Queso colanta
import quesoColanta1 from '../images/Productos/Quesos Productos/queso-colanta/queso-colanta380gr.png';
import quesoColanta2 from '../images/Productos/Quesos Productos/queso-colanta/queso-colanta200gr.png';
import quesoColanta3 from '../images/Productos/Quesos Productos/queso-colanta/queso-colanta400gr.png';
import quesoColanta4 from '../images/Productos/Quesos Productos/queso-colanta/queso-colanta500gr.png';
import quesoColanta5 from '../images/Productos/Quesos Productos/queso-colanta/queso-colanta250gr.png';


//Queso mozzarella
import quesoMozzarella1 from '../images/Productos/Quesos Productos/queso-mozarella/queso-mozzarella-colanta2.5kg.png';
import quesoMozzarella2 from '../images/Productos/Quesos Productos/queso-mozarella/queso-mozzarella-colanta500gr.png';
import quesoMozzarella3 from '../images/Productos/Quesos Productos/queso-mozarella/queso-mozzarella-colanta450gr.png';
import quesoMozzarella4 from '../images/Productos/Quesos Productos/queso-mozarella/queso-mozzarella-colanta250gr.png';
import quesoMozzarella5 from '../images/Productos/Quesos Productos/queso-mozarella/queso-mozzarela-cubitos-colanta500gr.png';
import quesoMozzarella6 from '../images/Productos/Quesos Productos/queso-mozarella/queso-mozzarella-colanta1kg.png';
import quesoMozzarella7 from '../images/Productos/Quesos Productos/queso-mozarella/queso-mozzarella-117tajadas-colanta2kg.png';


//Queso doblecrema
import quesoDobleCrema1 from '../images/Productos/Quesos Productos/queso-doblecrema/QUESO DOBLE CREMA-2.5KL.png';
import quesoDobleCrema2 from '../images/Productos/Quesos Productos/queso-doblecrema/QUESO DOBLE CREMA-1000GR.png';
import quesoDobleCrema3 from '../images/Productos/Quesos Productos/queso-doblecrema/QUESO DOBLE CREMA-250GR.png';


//Quesos madurados
import quesosMadurados1 from '../images/Productos/Quesos Productos/quesos-madurados/queso-holandes-colanta2.5kg.png';
import quesosMadurados2 from '../images/Productos/Quesos Productos/quesos-madurados/queso-holandes-colanta475gr.png';
import quesosMadurados3 from '../images/Productos/Quesos Productos/quesos-madurados/queso-holandes-colanta250gr.png';
import quesosMadurados4 from '../images/Productos/Quesos Productos/quesos-madurados/queso-holandes-colanta200gr.png';
import quesosMadurados5 from '../images/Productos/Quesos Productos/quesos-madurados/queso-monterey-jack-colanta250gr.png';
import quesosMadurados6 from '../images/Productos/Quesos Productos/quesos-madurados/queso-monterey-jack-colanta475gr.png';
import quesosMadurados7 from '../images/Productos/Quesos Productos/quesos-madurados/queso-centenario-colanta475gr.png';
import quesosMadurados8 from '../images/Productos/Quesos Productos/quesos-madurados/queso-centenario-colanta250gr.png';
import quesosMadurados9 from '../images/Productos/Quesos Productos/quesos-madurados/queso-chedar-colanta200gr.png';


//Queso parmesano
import quesoParmesano1 from '../images/Productos/Quesos Productos/queso-parmesano/queso-parmesano-colanta1000gr.png';
import quesoParmesano2 from '../images/Productos/Quesos Productos/queso-parmesano/queso-parmesano-colanta500gr.png';
import quesoParmesano3 from '../images/Productos/Quesos Productos/queso-parmesano/queso-parmesano-colanta40gr.png';
import quesoParmesano4 from '../images/Productos/Quesos Productos/queso-parmesano/queso-parmesano-colanrta250gr.png';
import quesoParmesano5 from '../images/Productos/Quesos Productos/queso-parmesano/queso-parmesano-colanta100gr.png';

//Queso cuñas y varios
import quesoCunasVarios1 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-chedar-colanta-cuna.png';
import quesoCunasVarios2 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-pecorino-colanta-cuna.png';
import quesoCunasVarios3 from '../images/Productos/Quesos Productos/quesos-cunas-varios/quesos-entrerios-colanta-cuna.png';
import quesoCunasVarios4 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-cruyere-colanta-cuna.png';
import quesoCunasVarios5 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-elemental-colanta-cuna.png';
import quesoCunasVarios6 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-tilsit-colanta-cuna.png';
import quesoCunasVarios7 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-parmesano-colanta-cuna.png';
import quesoCunasVarios8 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-de-asar-colanta-500gr.png';
import quesoCunasVarios9 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-de-asar-colanta250gr.png';
import quesoCunasVarios10 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-mozzarella-bufala-colanta250gr.png';
import quesoCunasVarios11 from '../images/Productos/Quesos Productos/quesos-cunas-varios/queso-fundido-colanta250gr.png';


//Untables y mantequillas
import untablesMantequillas1 from '../images/Productos/Untables y mantequillas Productos/sour-cream-colanta200gr.png';
import untablesMantequillas2 from '../images/Productos/Untables y mantequillas Productos/sour-cream-colanta400gr.png';
import untablesMantequillas3 from '../images/Productos/Untables y mantequillas Productos/souero-costeno-pasteurizado-ayotabuey-colanta230gr.png';
import untablesMantequillas4 from '../images/Productos/Untables y mantequillas Productos/crema-de-queso-slight-colanta230gr.png';
import untablesMantequillas5 from '../images/Productos/Untables y mantequillas Productos/crema-de-queso-colanta400gr.png';
import untablesMantequillas6 from '../images/Productos/Untables y mantequillas Productos/crema-de-queso-colanta230gr.png';
import untablesMantequillas7 from '../images/Productos/Untables y mantequillas Productos/crema-de-queso-colanta150gr.png';
import untablesMantequillas8 from '../images/Productos/Untables y mantequillas Productos/crema-de-queso-colanta15gr.png';
import untablesMantequillas9 from '../images/Productos/Untables y mantequillas Productos/mantequilla-sin-sal-colanta125gr.png';
import untablesMantequillas10 from '../images/Productos/Untables y mantequillas Productos/mantequilla-sin-sal-colanta250gr.png';
import untablesMantequillas11 from '../images/Productos/Untables y mantequillas Productos/mantequilla-con-sal-colanta125gr.png';
import untablesMantequillas12 from '../images/Productos/Untables y mantequillas Productos/mantequilla-con-sal-colanta250gr.png';
import untablesMantequillas13 from '../images/Productos/Untables y mantequillas Productos/mantequilla-sin-sal-colanta10gr.png';
import untablesMantequillas14 from '../images/Productos/Untables y mantequillas Productos/mantequilla-sin-sal-x2-unid-colanta 250gr.png';

//Untables y mantequillas
import cremaDeLeche1 from '../images/Productos/Crema de leche/crema-de-leche-colanta970gr.png';
import cremaDeLeche2 from '../images/Productos/Crema de leche/crema-de-leche-colanta870gr.png';
import cremaDeLeche3 from '../images/Productos/Crema de leche/crema-de-leche-colanta175gr.png';

//Productos institucionales
//Leche en polvo
import lecheEnPolvoInstitucional1 from '../images/Productos/Productos institucionales/Leche en polvo productos/leche-en-polvo-slight-colanta25kg.png';
import lecheEnPolvoInstitucional2 from '../images/Productos/Productos institucionales/Leche en polvo productos/leche-en-polvo-entera-colanta25kg.png';

//Arequipes
import arequipeInstitucional1 from '../images/Productos/Productos institucionales/Arequipe institucional productos/arequipe-solucion-colanta5kg.png';
import arequipeInstitucional2 from '../images/Productos/Productos institucionales/Arequipe institucional productos/arequipe-normal-colanta5kg.png';
import arequipeInstitucional3 from '../images/Productos/Productos institucionales/Arequipe institucional productos/arequipe-alta-densidad-colanta10kg.png';

//Crema de leche
import cremaDeLecheInstitucional1 from '../images/Productos/Productos institucionales/Crema de leche/crema-agria-colanta4kg (2).png';
import cremaDeLecheInstitucional2 from '../images/Productos/Productos institucionales/Crema de leche/crema-agria-colanta4kg.png';

//Queso institucional
import quesoInstitucional1 from '../images/Productos/Productos institucionales/Queso institucional/queso-costeno-colanta5kg.png';
import quesoInstitucional2 from '../images/Productos/Productos institucionales/Queso institucional/queso-para-uso-industrial-colanta5kg.png';
import quesoInstitucional3 from '../images/Productos/Productos institucionales/Queso institucional/queso-panaderia-colanta10kg.png';

//Mantequilla institucional
import mantequillaPasteurizadaInstitucional1 from '../images/Productos/Productos institucionales/Mantequilla pasteurizada/mantequilla-pasteurizada-colanta382oz.png';

//Yogures
//Yogurt cereal
import yogurtCereal1 from '../images/Productos/Yogures/yogurt-cereal/yogurt-con-hojuelas700gr.png';
import yogurtCereal2 from '../images/Productos/Yogures/yogurt-cereal/yogur-arroz-achocolatado-colanta700gr.png';
import yogurtCereal3 from '../images/Productos/Yogures/yogurt-cereal/yogurt-con-granola-colanta700gr.png';

//Yagur
import yagur1 from '../images/Productos/Yogures/yagur/yagur-vaso-fresa-colanta145ml.png';
import yagur2 from '../images/Productos/Yogures/yagur/yagur-vaso-melocoton-colanta145ml.png';
import yagur3 from '../images/Productos/Yogures/yagur/yagur-vaso-mora-colanta145ml.png';
import yagur4 from '../images/Productos/Yogures/yagur/yagur-bolsa-melocoton-colanta850ml.png';
import yagur5 from '../images/Productos/Yogures/yagur/yagur-bolsa-fresa-colanta850ml.png';
import yagur6 from '../images/Productos/Yogures/yagur/yagur-bolsa-mora-colanta850ml.png';




//Kumis
import kumis1 from '../images/Productos/Yogures/kumis/kumis-garrafa-colanta1000gr.png';
import kumis2 from '../images/Productos/Yogures/kumis/kumis-vaso-colanta200gr.png';
import kumis3 from '../images/Productos/Yogures/kumis/kumis-garrafa-4ltrs.png';


//Yogurt colanta
import yogurtColanta1 from '../images/Productos/Yogures/yogurt-colanta/yogurt-vaso-arequipe-colanta190ml.png';
import yogurtColanta2 from '../images/Productos/Yogures/yogurt-colanta/yogurt-vaso-pina-colada-colanta190ml.png';
import yogurtColanta3 from '../images/Productos/Yogures/yogurt-colanta/yogurt-vaso-melocoton-colanta190ml.png';
import yogurtColanta4 from '../images/Productos/Yogures/yogurt-colanta/yogurt-vaso-fresa-colanta190ml.png';
import yogurtColanta5 from '../images/Productos/Yogures/yogurt-colanta/yogurt-vaso-mora-colanta190ml.png';
import yogurtColanta6 from '../images/Productos/Yogures/yogurt-colanta/yogurt-bolsa-mora-colanta850ml.png';
import yogurtColanta7 from '../images/Productos/Yogures/yogurt-colanta/yogurt-bolsa-fresa-colanta850ml.png';
import yogurtColanta8 from '../images/Productos/Yogures/yogurt-colanta/yogurt-bolsa-melocoton-colanta850ml.png';
import yogurtColanta9 from '../images/Productos/Yogures/yogurt-colanta/yogurt-garrafa-fresa-colanta900ml.png';
import yogurtColanta10 from '../images/Productos/Yogures/yogurt-colanta/yogurt-garrafa-mora-colanta900ml.png';
import yogurtColanta11 from '../images/Productos/Yogures/yogurt-colanta/yogurt-garrafa-arequipe-colanta900ml.png';
import yogurtColanta12 from '../images/Productos/Yogures/yogurt-colanta/yogurt-garrafa-melocoton-colanta900ml.png';
import yogurtColanta13 from '../images/Productos/Yogures/yogurt-colanta/yogurt-vaso-guanabana-colanta190ml.png';
import yogurtColanta14 from '../images/Productos/Yogures/yogurt-colanta/yogur-garrafa-melocoton-colanta4000ml.png';
import yogurtColanta15 from '../images/Productos/Yogures/yogurt-colanta/yogurt-garrafa-guanabana-colanta900ml.png';

//Yogurt Vida
import yogurtVida1 from '../images/Productos/Yogures/yogurt-vida/yogurt-fresa-vida-colanta100ml.png';
import yogurtVida2 from '../images/Productos/Yogures/yogurt-vida/yogurt-mora-vida-colanta100ml.png';
import yogurtVida3 from '../images/Productos/Yogures/yogurt-vida/yogurt-melocoton-viad-colanta100ml.png';
import yogurtVida4 from '../images/Productos/Yogures/yogurt-vida/yogurt-vida-empaque-x6-surtidos-colanta100ml.png';

//Yogurt bufala
import yogurtBufala1 from '../images/Productos/Yogures/yogurt-bufala/yogur-de-bufala-fresa-colanta170ml.png';
import yogurtBufala2 from '../images/Productos/Yogures/yogurt-bufala/yogur-de-bufala-mora-colanta170ml.png';
import yogurtBufala3 from '../images/Productos/Yogures/yogurt-bufala/yogur-de-bufala-maracuya-colanta170ml.png';


//Yogurt sligth
import yogurtSlight1 from '../images/Productos/Yogures/yogurt-sligth/yogurt-slight-vaso-fresa-colanta190ml.png';
import yogurtSlight2 from '../images/Productos/Yogures/yogurt-sligth/yogur-slight-vaso-mora-colanta190ml.png';
import yogurtSlight3 from '../images/Productos/Yogures/yogurt-sligth/yogur-slight-vaso-melocoton-colanta190ml.png';
import yogurtSlight4 from '../images/Productos/Yogures/yogurt-sligth/yogur-slight-bolsa-melocoton-colanta190ml.png';
import yogurtSlight5 from '../images/Productos/Yogures/yogurt-sligth/yogur-slight-bolsa-fresa-colanta190ml.png';
import yogurtSlight6 from '../images/Productos/Yogures/yogurt-sligth/yogur-slight-bolsa-mora-colanta190ml.png';

//Paquetes de yogurt
import paquetesYogurt1 from '../images/Productos/Yogures/paquetes-de-yogurt/yagur-x6und-colanta200ml.png';
import paquetesYogurt2 from '../images/Productos/Yogures/paquetes-de-yogurt/yagur-x7und-colanta200ml.png';
import paquetesYogurt3 from '../images/Productos/Yogures/paquetes-de-yogurt/yogur-x6und-colanta190ml.png';
import paquetesYogurt4 from '../images/Productos/Yogures/paquetes-de-yogurt/yogur-colanta-slight-x6und-colanta200ml.png';
import paquetesYogurt7 from '../images/Productos/Yogures/paquetes-de-yogurt/kumis-x6und-colanta200ml.png';


//Yogurt griego
import yogurtGriego1 from '../images/Productos/Yogures/yogurt-griego/yogur-griego-limon-colanta125gr.png';
import yogurtGriego2 from '../images/Productos/Yogures/yogurt-griego/yogur-melocoton-viad-colanta125gr.png';
import yogurtGriego3 from '../images/Productos/Yogures/yogurt-griego/yogur-griego-vainilla-colanta125gr.png';
import yogurtGriego4 from '../images/Productos/Yogures/yogurt-griego/yogur-griego-fresa-colanta125gr.png';

//Yogurt benecol
import yogurtBenecol1 from '../images/Productos/Yogures/yogurt-benecol/yogur-benecol-garrafa-fresa-colanta1000gr.png';
import yogurtBenecol2 from '../images/Productos/Yogures/yogurt-benecol/yogur-benecol-garrafa-frutos-rojos-colanta1000gr.png';
import yogurtBenecol3 from '../images/Productos/Yogures/yogurt-benecol/yogur-benecol-garrafa-frutos-verdes-colanta1000gr.png';
import yogurtBenecol4 from '../images/Productos/Yogures/yogurt-benecol/yogur-benecol-garrafa-melocoton-colanta1000gr.png';


//Kids
import kids1 from '../images/Productos/Kids Productos/petito-colanta100gr.png';
import kids2 from '../images/Productos/Kids Productos/petito-colanta100gr2.png';
import kids3 from '../images/Productos/Kids Productos/petito-colanta100gr3.png';
import kids4 from '../images/Productos/Kids Productos/frescolanta-vainilla-bolsa200ml.png';
import kids5 from '../images/Productos/Kids Productos/frescolanta-fresa-bolsa200ml2.png';
import kids6 from '../images/Productos/Kids Productos/frescolanta-chocolate-bolsa200ml.png';
import kids7 from '../images/Productos/Kids Productos/frescolanta-chocolate-caja200ml.png';
import kids8 from '../images/Productos/Kids Productos/frescolanta-chocolate-caja200ml2.png';
import kids9 from '../images/Productos/Kids Productos/frescolanta-fresa-caja200ml.png';
import kids10 from '../images/Productos/Kids Productos/gelatina-twisty-fresa-colanta120gr.png';
import kids11 from '../images/Productos/Kids Productos/gelatina-twisty-uva-colanta120gr.png';
import kids12 from '../images/Productos/Kids Productos/gelatina-twisty-naranja-colanta120gr.png';
import kids13 from '../images/Productos/Kids Productos/gelatina-twisty-mora-colanta120gr.png';
import kids14 from '../images/Productos/Kids Productos/arequipe-kipe-colanta50gr.png';
// import kids15 from '../images/Productos/Kids Productos/arequipe-kipe-colanta20gr.png';
import kids16 from '../images/Productos/Kids Productos/arequipe-kipe-x11und-colanta20gr.png';
import kids17 from '../images/Productos/Kids Productos/queso-doble-crema-ganitas-colanta50gr.png';

//Arequipes y leche condensada
import arequipes1 from '../images/Productos/Arequipes y leche condensada productos/arequipe-colanta250gr2.png';
import arequipes2 from '../images/Productos/Arequipes y leche condensada productos/arequipe-colanta1kg.png';
import arequipes3 from '../images/Productos/Arequipes y leche condensada productos/arequipe-colanta450gr.png';
import arequipes4 from '../images/Productos/Arequipes y leche condensada productos/arequipe-colanta250gr.png';
import arequipes5 from '../images/Productos/Arequipes y leche condensada productos/leche-condensada-colanta1000gr.png';
import arequipes6 from '../images/Productos/Arequipes y leche condensada productos/leche-condensafa-colanta300gr.png';
import arequipes7 from '../images/Productos/Arequipes y leche condensada productos/manjar-blanco-colanta250gr.png';
import arequipes8 from '../images/Productos/Arequipes y leche condensada productos/miel-colanta450gr.png';
import arequipes9 from '../images/Productos/Arequipes y leche condensada productos/arequipe-bufalfa-colanta270gr.png';
import arequipes10 from '../images/Productos/Arequipes y leche condensada productos/leche-condensada-x12und-colanta20gr.png';

//Carnicos
//Carnes frias
import carnesFrias1 from '../images/Productos/Carnes frias productos/tocineta-colanta250gr.png';
import carnesFrias2 from '../images/Productos/Carnes frias productos/tocineta-colanta900gr.png';
import carnesFrias3 from '../images/Productos/Carnes frias productos/tocineta-colanta450gr.png';
import carnesFrias4 from '../images/Productos/Carnes frias productos/salchicha-xl-colanta1000gr.png';
import carnesFrias5 from '../images/Productos/Carnes frias productos/salchicha-seleccionada-colanta450gr.png';
import carnesFrias6 from '../images/Productos/Carnes frias productos/jamon-seleccionado-colanta450gr.png';
import carnesFrias7 from '../images/Productos/Carnes frias productos/mortadela-seleccionada-colanta450gr.png';
import carnesFrias8 from '../images/Productos/Carnes frias productos/salchichon-cervecero-premium-colanta50gr.png';
import carnesFrias9 from '../images/Productos/Carnes frias productos/morcilla-colanta500gr.png';
import carnesFrias10 from '../images/Productos/Carnes frias productos/black-pudding-colanta300gr.png';
import carnesFrias11 from '../images/Productos/Carnes frias productos/salchichon-fino-colanta1000gr.png';
import carnesFrias12 from '../images/Productos/Carnes frias productos/salchichon-fino-colanta450gr.png';
import carnesFrias13 from '../images/Productos/Carnes frias productos/salchichon-fino-colanta225gr.png';
import carnesFrias14 from '../images/Productos/Carnes frias productos/salchichon-fino-colanta125gr.png';
// import carnesFrias15 from '../images/Productos/Carnes frias productos/salchichon-cervecero-colanta1000gr.png';
import carnesFrias16 from '../images/Productos/Carnes frias productos/salchichon-cervecero-colanta500gr.png';
import carnesFrias17 from '../images/Productos/Carnes frias productos/salchichon-montefrio-colanta225gr.png';
import carnesFrias18 from '../images/Productos/Carnes frias productos/salchichon-montefrio-colanta500gr.png';
import carnesFrias19 from '../images/Productos/Carnes frias productos/carne-de-hamburguesa-montefrio-colanta500gr.png';
import carnesFrias20 from '../images/Productos/Carnes frias productos/carne-de-hamburguesa-premium-colanta500gr.png';
import carnesFrias21 from '../images/Productos/Carnes frias productos/salchicha-wets-montefrio-colanta430gr.png';
import carnesFrias22 from '../images/Productos/Carnes frias productos/chorizo-ternera-colanta450gr.png';
import carnesFrias23 from '../images/Productos/Carnes frias productos/chorizo-coctel-jarapeno-colanta300gr.png';
import carnesFrias24 from '../images/Productos/Carnes frias productos/chorizo-santarrosano-colanta500gr.png';
// import carnesFrias25 from '../images/Productos/Carnes frias productos/queso-doble-crema-ganitas-colanta50gr.png';
import carnesFrias26 from '../images/Productos/Carnes frias productos/chorizo-campesino-colanta450gr.png';
import carnesFrias27 from '../images/Productos/Carnes frias productos/salchicha-tipica-colanta225gr.png';
import carnesFrias28 from '../images/Productos/Carnes frias productos/salchica-tipica-colanta250gr.png';
import carnesFrias29 from '../images/Productos/Carnes frias productos/butifarra-colanta450gr.png';
import carnesFrias30 from '../images/Productos/Carnes frias productos/carnicos-para-brochetas-colanta500gr.png';
import carnesFrias31 from '../images/Productos/Carnes frias productos/salchicha-viena-colanta170gr.png';
import carnesFrias32 from '../images/Productos/Carnes frias productos/chorizo-campesino-colanta250gr.png';
import carnesFrias33 from '../images/Productos/Carnes frias productos/mortadela-seleccionada-colanta225gr.png';
import carnesFrias34 from '../images/Productos/Carnes frias productos/jamon-seleccionado-colanta225gr.png';
import carnesFrias35 from '../images/Productos/Carnes frias productos/chorizo-santarrosano-tajado-colanta225gr.png';


//Carnicos institucionales
import carnicosInstitucionales1 from '../images/Productos/Carnicos institucionales productos/jamon-selecionado-colanta902gr.png';
import carnicosInstitucionales2 from '../images/Productos/Carnicos institucionales productos/chorizo-de-ternera-premium-colanta2813kg.png';
import carnicosInstitucionales3 from '../images/Productos/Carnicos institucionales productos/chorizo-campesino-colanta2.5kg.png';
import carnicosInstitucionales4 from '../images/Productos/Carnicos institucionales productos/chorizo-con-ternera-colanta1125gr.png';
import carnicosInstitucionales5 from '../images/Productos/Carnicos institucionales productos/chorizo-santarosano-colanta1125kg.png';
import carnicosInstitucionales6 from '../images/Productos/Carnicos institucionales productos/morcilla-colanta1125gr.png';

//Carnes al vacio
import carnesAlVacio1 from '../images/Productos/Carne al vacio Productos/carne-de-cerdo-colanta400gr.png';
import carnesAlVacio2 from '../images/Productos/Carne al vacio Productos/carne-de-res-colanta400gr.png';
import carnesAlVacio3 from '../images/Productos/Carne al vacio Productos/costilla-de-cerdo-colanta500gr.png';
import carnesAlVacio4 from '../images/Productos/Carne al vacio Productos/tocino-jamonado-colanta400gr.png';

//Tampico
import tampico1 from '../images/Productos/Tampico Productos/tampico-colanta-garrafa1000ml.png';
import tampico2 from '../images/Productos/Tampico Productos/tampico-colanta-garrafa2000ml.png';
import tampico3 from '../images/Productos/Tampico Productos/tampico-colanta-garrafa4000ml.png';
// import tampico4 from '../images/Productos/Tampico Productos/tampico-colanta-tarro5000ml.png';
import tampico5 from '../images/Productos/Tampico Productos/tampico-colanta-caja200ml.png';
import tampico6 from '../images/Productos/Tampico Productos/tampico-colanta-tarro330ml.png';
import tampico7 from '../images/Productos/Tampico Productos/tampico-colanta-tarro500ml.png';
import tampico8 from '../images/Productos/Tampico Productos/tampico-colanta-tarro240ml.png';
import tampico9 from '../images/Productos/Tampico Productos/tampico-colanta-tarro250ml.png';
import tampico10 from '../images/Productos/Tampico Productos/tampico-colanta-bolsa200ml.png';
import tampico11 from '../images/Productos/Tampico Productos/tampico-colanta-0-azucar-garrafa2000ml.png';
import tampico12 from '../images/Productos/Tampico Productos/tampico-colanta-0-azucar-tarro500ml.png';
// import tampico13 from '../images/Productos/Tampico Productos/tampico-colanta-0-azucar-tarro1000ml.png';
import tampico14 from '../images/Productos/Tampico Productos/tampico-colanta-0-azucar-bolsa250ml.png';
import tampico15 from '../images/Productos/Tampico Productos/tampico-colanta-o-azucar-tarro250ml.png';
import tampico16 from '../images/Productos/Tampico Productos/tampico-colanta-x10und-caja200ml.png';
import tampico17 from '../images/Productos/Tampico Productos/tampico-colanta-x6und-caja250ml.png';
import tampico18 from '../images/Productos/Tampico Productos/jugo-de-naranja-en-caja-colanta1000ml.png';
import tampico19 from '../images/Productos/Tampico Productos/agua-montefrio-tarro600ml.png';
import tampico20 from '../images/Productos/Tampico Productos/tampico-colanta-caja1000ml.png';

//Avena
import avena1 from '../images/Productos/Avena Productos/avena-sabor-canela-colanta200ml.png';
import avena2 from '../images/Productos/Avena Productos/avena-sabor-natural-colanta1000ml.png';
import avena3 from '../images/Productos/Avena Productos/avena-sabor-natural-colanta1100ml.png';
import avena4 from '../images/Productos/Avena Productos/avena-sabor-canelax6und-colanta.png';

//Prolinco
import prolinco1 from '../images/Productos/Prolinco/leche-entera-uht-prolinco900ml.png';
import prolinco2 from '../images/Productos/Prolinco/leche-entera-uht-prolinco400ml.png';
import prolinco3 from '../images/Productos/Prolinco/leche-deslactosada-uht-prolinco900ml.png';
import prolinco4 from '../images/Productos/Prolinco/quesito-prolinco500gr.png';


//_____________________________________________________________________________________________________
//Abarrotes

//Kelloggs
import kellogs1 from '../images/Productos/Abarrotes/kelloggs/choco-krispis-kelloggs30gr.png';
import kellogs2 from '../images/Productos/Abarrotes/kelloggs/choco-krispis-kelloggs120gr.png';
import kellogs3 from '../images/Productos/Abarrotes/kelloggs/kelloggs-zucaritas30gr.png';
import kellogs4 from '../images/Productos/Abarrotes/kelloggs/kelloggs-zucaritas120gr.png';

//Copelia
import copelia1 from '../images/Productos/Abarrotes/copelia/copelia-x50und-yupi23gr.png';
import copelia2 from '../images/Productos/Abarrotes/copelia/copelia-x24und-yupi23gr.png';
// import copelia3 from '../images/Productos/Abarrotes/copelia/copelia-yupi23gr.png';


//Aburra
import aburra1 from '../images/Productos/Abarrotes/aburra/salsa-aji-aburra165ml.png';
import aburra2 from '../images/Productos/Abarrotes/aburra/salsa-teriyaki-aburra190ml.png';
import aburra3 from '../images/Productos/Abarrotes/aburra/salsa-soya-aburra165ml.png';
import aburra4 from '../images/Productos/Abarrotes/aburra/salsa-para-carnes-aburra185gr.png';
import aburra5 from '../images/Productos/Abarrotes/aburra/salsa-negra-aburra200ml.png';
import aburra6 from '../images/Productos/Abarrotes/aburra/salsa-sabor-a-humo-aburra165ml.png';
import aburra7 from '../images/Productos/Abarrotes/aburra/salsa-de-ajo-aburra165ml.png';
import aburra8 from '../images/Productos/Abarrotes/aburra/salsa-chimichurri-aburra165ml.png';
import aburra9 from '../images/Productos/Abarrotes/aburra/salsa-bbq-aburra190ml.png';
import aburra10 from '../images/Productos/Abarrotes/aburra/arvejas-con-zanahoria-aburra580gr.png';
import aburra11 from '../images/Productos/Abarrotes/aburra/maiz-tierno-aburra340gr.png';
import aburra12 from '../images/Productos/Abarrotes/aburra/frijoles-antioquenos-con-tocino-aburra300gr.png';
import aburra13 from '../images/Productos/Abarrotes/aburra/lentejas-aburra500gr.png';
import aburra14 from '../images/Productos/Abarrotes/aburra/atun-marcela-en-aceite-aburra150gr.png';
import aburra15 from '../images/Productos/Abarrotes/aburra/atun-marcela-en-agua-aburra150gr.png';
import aburra16 from '../images/Productos/Abarrotes/aburra/atun-marcela-en-aceite-x3und-aburra150gr.png';
import aburra17 from '../images/Productos/Abarrotes/aburra/sardinas-en-salsa-de-tomate-aburra125gr.png';
import aburra18 from '../images/Productos/Abarrotes/aburra/sal-refinada.aburra500gr.png';
import aburra19 from '../images/Productos/Abarrotes/aburra/salchicas-viena-de-pollo-zenu150gr.png';
import aburra20 from '../images/Productos/Abarrotes/aburra/lentejas-con-papa-y-zanahoria-aburra300gr.png';

//Vive100-Saviloe
import saviloe1 from '../images/Productos/Abarrotes/saviloe-vive100/sabiloe-con-fibra320ml.png';
import saviloe2 from '../images/Productos/Abarrotes/saviloe-vive100/vive100380ml.png';


//Bamby
import bamby1 from '../images/Productos/Abarrotes/bamby/aromatica-manzanilla-x20und-bamby5gr.png';
import bamby2 from '../images/Productos/Abarrotes/bamby/aromatica-yerbabuena-x20und-bamby5gr.png';

//El trapiche
import trapiche1 from '../images/Productos/Abarrotes/el trapiche/aromatica-limon-cubos-x24und-el-trapiche160gr.png';
import trapiche2 from '../images/Productos/Abarrotes/el trapiche/aromatica-surtido-sobres-x25und-el-trapiche6gr.png';

//Pompeya
import pompeya1 from '../images/Productos/Abarrotes/pompeya/salsa-de-bbqpompeya4000gr.png';
import pompeya2 from '../images/Productos/Abarrotes/pompeya/salsa-de-pina-pompeya4000gr.png';
import pompeya3 from '../images/Productos/Abarrotes/pompeya/salsa-mostaza-pompeya4000gr.png';
import pompeya4 from '../images/Productos/Abarrotes/pompeya/salsa-mayonesa.pompeya4000gr.png';
import pompeya5 from '../images/Productos/Abarrotes/pompeya/salsa-mayomostaza-pompeya4000gr.png';
import pompeya6 from '../images/Productos/Abarrotes/pompeya/salsa-de-tomate-pompeya4000gr.png';

//Bary
import bary1 from '../images/Productos/Abarrotes/bary/salsa-de-tomate-paquete-x50und-bary8gr.png';
import bary2 from '../images/Productos/Abarrotes/bary/salsa-rosada-paquete-x50und-bary8gr.png';

//Aderezos
import aderezos1 from '../images/Productos/Abarrotes/aderezos/salsa-de-tomate-aderezos1000gr.png';
import aderezos2 from '../images/Productos/Abarrotes/aderezos/salsa-rosada-aderezos1000gr.png';
import aderezos3 from '../images/Productos/Abarrotes/aderezos/salsa-de-chocolate-aderezos1000gr.png';
import aderezos4 from '../images/Productos/Abarrotes/aderezos/vinagreta-aderezos3340gr.png';

//Oreo tosh
import oreo1 from '../images/Productos/Abarrotes/oreo tosh/galletas-oreo-x12und-oreo432gr.png';
import oreo2 from '../images/Productos/Abarrotes/oreo tosh/galletas-oreo-x6-und-oreo216gr.png';
import oreo3 from '../images/Productos/Abarrotes/oreo tosh/galletas-tohs-miel-x9und-tosh143gr.png';

//Festival
import festival1 from '../images/Productos/Abarrotes/festival/festival-x12und-vainilla-noel600gr.png';
import festival2 from '../images/Productos/Abarrotes/festival/festival-x12und-limon-noel600gr.png';
import festival3 from '../images/Productos/Abarrotes/festival/festival-x12und-fresa-noel600gr.png';
import festival4 from '../images/Productos/Abarrotes/festival/festival-x12und-choclate-noel600gr.png';

//Club social
import clubSocial1 from '../images/Productos/Abarrotes/club-social/galletas-original-x9und-club-social25gr.png';
import clubSocial2 from '../images/Productos/Abarrotes/club-social/galletas-integral-x9und-club-social25gr.png';

//Natillas y pancakes
import natillas1 from '../images/Productos/Konfyt/natillas y pancakes/natilla-tradicional-canela-konfyt120gr.png';
import natillas2 from '../images/Productos/Konfyt/natillas y pancakes/natilla-arequipe-konfyt120gr.png';
import natillas3 from '../images/Productos/Konfyt/natillas y pancakes/natilla-coco-konfyt120gr.png';
import natillas4 from '../images/Productos/Konfyt/natillas y pancakes/pancakes-konfyt300gr.png';
import natillas5 from '../images/Productos/Konfyt/natillas y pancakes/miel-syrup-kvital-konfyt-360gr.png';

//Colanta abarrotes
import colanta1 from '../images/Productos/Abarrotes/colanta/arroz-colanta1000gr.png';


//Konfyt
//Gelatinas
import gelatinas1 from '../images/Productos/Konfyt/gelatinas/postre-de-melocoton-konfyt11gr.png';
import gelatinas2 from '../images/Productos/Konfyt/gelatinas/postre-de-fresa-konfyt11gr.png';
import gelatinas3 from '../images/Productos/Konfyt/gelatinas/postre-de-mora-konfyt11gr.png';
import gelatinas4 from '../images/Productos/Konfyt/gelatinas/pudin-de-vainilla-konfyt24gr.png';
import gelatinas5 from '../images/Productos/Konfyt/gelatinas/pudin-de-chocolate-konfyt24gr.png';
import gelatinas6 from '../images/Productos/Konfyt/gelatinas/pudin-de-arequipe-konfyt24gr.png';
import gelatinas7 from '../images/Productos/Konfyt/gelatinas/gelatina-sabores-surtidos-x4-pompeya-40gr.png';

//Mermeladas
import mermeladas1 from '../images/Productos/Konfyt/mermeladas/arequipe-konfyt280gr.png';
import mermeladas2 from '../images/Productos/Konfyt/mermeladas/mermelada-de-fresa-konfyt150gr.png';
import mermeladas3 from '../images/Productos/Konfyt/mermeladas/mermelada-de-mora-konfyt250gr.png';
import mermeladas4 from '../images/Productos/Konfyt/mermeladas/mermelada-de-pina-konfyt250gr.png';
import mermeladas5 from '../images/Productos/Konfyt/mermeladas/dulce-de-brevas-konfyt500gr.png';

//Natillas pancakes
import dulces1 from '../images/Productos/Konfyt/dulces/caramelo-de-leche-konfyt30gr.png';
import dulces2 from '../images/Productos/Konfyt/dulces/dulce-de-guayaba-konfyt220gr.png';

//Sucralosa
import sucrasola1 from '../images/Productos/Konfyt/sucralosa/sucralosa-x250und-konfyt100gr.png';
import sucrasola2 from '../images/Productos/Konfyt/sucralosa/sucrasola-x100und-konfyt-70gr.png';

//Sucralosa
import sinAzucarAnadida1 from '../images/Productos/Konfyt/sin azucar añadido/dulce-con-leche-queso-urabeno-konfyt-180gr.png';
import sinAzucarAnadida2 from '../images/Productos/Konfyt/sin azucar añadido/galletas-sabor-a-mantequilla-konfyt140gr.png';

//Aseo
//Gutvan
import gutvan1 from '../images/Productos/Aseo/Gutvan/vaso-desechable-blanco-gutvan6.png';
import gutvan2 from '../images/Productos/Aseo/Gutvan/vaso-desechable-blanco-gutvan7.png';
import gutvan3 from '../images/Productos/Aseo/Gutvan/vaso-desechable-transparente-gutvan7.png';
import gutvan4 from '../images/Productos/Aseo/Gutvan/vaso-desechable-transparente-gutvan10.png';
import gutvan5 from '../images/Productos/Aseo/Gutvan/vaso-desechable-transparente-gutvan12.png';
import gutvan6 from '../images/Productos/Aseo/Gutvan/vaso-desechable-transparente-gutvan14.png';
import gutvan7 from '../images/Productos/Aseo/Gutvan/vaso-desechable-transparente-gutvan16.png';
import gutvan8 from '../images/Productos/Aseo/Gutvan/tapa-de-vaso-gutvan.png';

//Nube
import nube1 from '../images/Productos/Aseo/Nube/paquete-de-servilletas-x300-nube.png';
import nube2 from '../images/Productos/Aseo/Nube/caja-de-servilletas-x15-pqt-nube.png';

//Fassi
import fassi1 from '../images/Productos/Aseo/Fassi/jabon-lava-platos-fassi-colanta900gr.png';

//Incauca
import incauca1 from '../images/Productos/Aseo/Incauca/azucar-blanca-incauca2.5kg.png';
import incauca2 from '../images/Productos/Aseo/Incauca/paquete-de-turbipaks-x200-incauca.png';

//Member's selection
import members1 from '../images/Productos/Aseo/Members selection/endulzante-swestener2000gr.png';


//Yupi
//Loncheras
import loncheras1 from '../images/Productos/Yupi/Loncheras/rizadas-mayonesa.x12und-yupi251gr.png';
import loncheras2 from '../images/Productos/Yupi/Loncheras/lonchera-diverpack-25und-yupi483gr.png';
import loncheras3 from '../images/Productos/Yupi/Loncheras/lonchera-megapack-30und-yupi594gr.png';

//Rizadas
import rizadas1 from '../images/Productos/Yupi/Rizadas/rizadas-mayonesa.x12und-yupi300gr.png';
import rizadas2 from '../images/Productos/Yupi/Rizadas/rizadas-naturales-x12und-yupi300gr.png';
import rizadas3 from '../images/Productos/Yupi/Rizadas/rizadas-limon-x12und-yupi300gr.png';
import rizadas4 from '../images/Productos/Yupi/Rizadas/rizadas-tomate-x12und-yupi300gr.png';
import rizadas5 from '../images/Productos/Yupi/Rizadas/rizadas-limon-yupi105gr.png';
import rizadas6 from '../images/Productos/Yupi/Rizadas/rizadas-mayonesa-yupi105gr.png';
import rizadas7 from '../images/Productos/Yupi/Rizadas/rizadas-nstural-yupi110gr.png';
import rizadas8 from '../images/Productos/Yupi/Rizadas/rizadas-tomate-yupi105gr.png';

//Palomitas tozinetas
import palomitas1 from '../images/Productos/Yupi/Palomitas-tocinetas/tocinetas-fred-x12und-yupi156gr.png';
import palomitas2 from '../images/Productos/Yupi/Palomitas-tocinetas/palomitas-x12und-yupi240gr.png';
import palomitas3 from '../images/Productos/Yupi/Palomitas-tocinetas/palomita-caramelo-yupi170gr.png';
import palomitas4 from '../images/Productos/Yupi/Palomitas-tocinetas/palomitas-galleta-con-crema-yupi55gr.png';

//Golpe
import golpe1 from '../images/Productos/Yupi/Golpe/golpe-original-x8und-yupi45gr.png';
import golpe2 from '../images/Productos/Yupi/Golpe/golpe-original-yupi140gr.png';
import golpe3 from '../images/Productos/Yupi/Golpe/golpe-natural-yupi45gr.png';
import golpe4 from '../images/Productos/Yupi/Golpe/golpe-natural-yupi140gr.png';
import golpe5 from '../images/Productos/Yupi/Golpe/golpe-bbq-yupi45gr.png';
import golpe6 from '../images/Productos/Yupi/Golpe/golpe-bbq-yupi140gr.png';
import golpe7 from '../images/Productos/Yupi/Golpe/golpe-limon-x8und-yupi45gr.png';
import golpe8 from '../images/Productos/Yupi/Golpe/golpe-limon-yupi140gr.png';
import golpe9 from '../images/Productos/Yupi/Golpe/golpe-rancherox-8und-yupi45gr.png';
import golpe10 from '../images/Productos/Yupi/Golpe/golpe-ranchero-yupi140gr.png';

//Tosti
import tosti1 from '../images/Productos/Yupi/Tosti/tosti-empanadas-limon-yupi28gr.png';
import tosti2 from '../images/Productos/Yupi/Tosti/tosti-empanadas-limon-yupi150gr.png';
import tosti3 from '../images/Productos/Yupi/Tosti/tosti-empanadas-carne-x12und-yupi28gr.png';
import tosti4 from '../images/Productos/Yupi/Tosti/tosti-picanticos-hot-x12und-yupi28gr.png';
import tosti5 from '../images/Productos/Yupi/Tosti/tosti-picanticos-medium-x12und-yupi28gr.png';
import tosti6 from '../images/Productos/Yupi/Tosti/tosti-nachos-yupi180gr.png';
import tosti7 from '../images/Productos/Yupi/Tosti/tosti-pizza-queso-x12und-yupi28gr.png';
import tosti8 from '../images/Productos/Yupi/Tosti/tosti-arepa-x12und-yupi28gr.png';

//Yupis
import yupis1 from '../images/Productos/Yupi/Yupis/yupis-picantes-yupi44gr.png';
import yupis2 from '../images/Productos/Yupi/Yupis/yupis-salados-x3und-yupi44gr.png';

//clasicas-yupi
import clasicas1 from '../images/Productos/Yupi/Clasicas/clasicas-limon-x8und-yupi36gr.png';
import clasicas2 from '../images/Productos/Yupi/Clasicas/clasicas-mayonesa-x8und-yupi36gr.png';
import clasicas3 from '../images/Productos/Yupi/Clasicas/clasicas-pollo-a-la-bbq-x8und-yupi36gr.png';

//Mekato
import mekato1 from '../images/Productos/Yupi/Mekato/mekato-limon-x12und-yupi25gr.png';
import mekato2 from '../images/Productos/Yupi/Mekato/mekato-limon-yupi100gr.png';
import mekato3 from '../images/Productos/Yupi/Mekato/crespas-natural-x12und-yupi.png';

//Varios-yupi
import variosYupi1 from '../images/Productos/Yupi/Varios-yupi/anillos-de-cebolla-x8und-yupi21gr.png';
import variosYupi2 from '../images/Productos/Yupi/Varios-yupi/multicereal x6und yupi 38gr.png';
import variosYupi3 from '../images/Productos/Yupi/Varios-yupi/lizas-natural-de-tomate-x12und-yupi27gr.png';


//Colanta

//Leche en polvo
const lecheEnPolvoProductos = [
    {
        nombre: "Leche en polvo entera 900gr",
        img: lecheEnPolvo1,
    },
    {
        nombre: "Leche en polvo entera 380gr",
        img: lecheEnPolvo2,
    },
    {
        nombre: "Leche en polvo entera 200gr",
        img: lecheEnPolvo3,
    },
    {
        nombre: "Leche en polvo entera 25gr",
        img: lecheEnPolvo4,
    },
    {
        nombre: "Leche en polvo semidescremada 380gr",
        img: lecheEnPolvo6,
    },
    {
        nombre: "Leche en polvo descremada 380gr",
        img: lecheEnPolvo7,
    },
]

//Leche en polvo montefrio
const lecheEnPolvoMontefrioProductos = [
    {
        nombre: "Leche en polvo montefrio entera 200gr",
        img: lecheEnPolvoMontefrio1,
    },
    {
        nombre: "Leche en polvo montefrio entera 900gr",
        img: lecheEnPolvoMontefrio2,
    },
]

//Leche UHT
const lecheUHTProductos = [
    {
        nombre: "Leche entera UHT bolsa 1000ml",
        img: lecheUHT1,
    },
    {
        nombre: "Leche entera UHT bolsa 900ml",
        img: lecheUHT2,
    },
    {
        nombre: "Leche entera UHT caja 1000ml",
        img: lecheUHT3,
    },
    {
        nombre: "Leche entera UHT bolsa 200ml",
        img: lecheUHT4,
    },
    {
        nombre: "Leche entera UHT caja 200ml",
        img: lecheUHT5,
    },
    {
        nombre: "Leche deslactosada UHT bolsa 1000ml",
        img: lecheUHT6,
    },
    {
        nombre: "Leche deslactosada UHT caja 900ml",
        img: lecheUHT7,
    },
    {
        nombre: "Leche montefrio UHT caja 900ml",
        img: lecheUHT8,
    },
    {
        nombre: "Leche deslactosada UHT caja 1000ml",
        img: lecheUHT9,
    },
    {
        nombre: "Leche semidescremada UHT caja 1050ml",
        img: lecheUHT10,
    },
    {
        nombre: "Leche descremada UHT caja 1050ml",
        img: lecheUHT11,
    },
    {
        nombre: "Leche slight UHT caja 1080ml",
        img: lecheUHT12,
    },
    {
        nombre: "Leche deslactosada semidescremada UHT bolsa 400ml",
        img: lecheUHT13,
    },
    {
        nombre: "Leche entera UHT bolsa 400ml",
        img: lecheUHT14,
    }
]

//Quesos
//Quesitos y cuajadas
const quesitosCuajadasProductos = [
    {
        nombre: "Quesito montefrio 380gr",
        img: quesitosCuajadas1,
    },
    {
        nombre: "Quesito montefrio 200gr",
        img: quesitosCuajadas2,
    },
    {
        nombre: "Cuajada colanta 400gr",
        img: quesitosCuajadas3,
    },
    {
        nombre: "Cuajada colanta 500gr",
        img: quesitosCuajadas4,
    },
    {
        nombre: "Cuajada colanta 250gr",
        img: quesitosCuajadas5,
    },
    // {
    //     nombre: "Queso costeño colanta 1kg",
    //     img: quesitosCuajadas6,
    // },
]

//Quesitos y cuajadas
const quesoColantaProdcutos = [
    {
        nombre: "Queso colanta 380gr",
        img: quesoColanta1,
    },
    {
        nombre: "Queso colanta 200gr",
        img: quesoColanta2,
    },
    {
        nombre: "Queso colanta 400gr",
        img: quesoColanta3,
    },
    {
        nombre: "Queso colanta 500gr",
        img: quesoColanta4,
    },
    {
        nombre: "Queso colanta 250gr",
        img: quesoColanta5,
    },
]

//Queso mozzarella
const quesoMozzarellaProductos = [
    {
        nombre: "Queso mozzarella colanta 2.5kg",
        img: quesoMozzarella1,
    },
    {
        nombre: "Queso mozzarella colanta 500gr",
        img: quesoMozzarella2,
    },
    {
        nombre: "Queso mozzarella colanta 450gr",
        img: quesoMozzarella3,
    },
    {
        nombre: "Queso mozzarella colanta 250gr",
        img: quesoMozzarella4,
    },
    {
        nombre: "Queso mozzarella cubitos colanta 500gr",
        img: quesoMozzarella5,
    },
    {
        nombre: "Queso mozzarella colanta 1kg",
        img: quesoMozzarella6,
    },
    {
        nombre: "Queso mozzarella 117 tajadas colanta 2kg",
        img: quesoMozzarella7,
    }
]


//Queso doble crema
const quesoDobleCremaProductos = [
    {
        nombre: "Queso doble crema 2.5kg",
        img: quesoDobleCrema1,
    },
    {
        nombre: "Queso doble crema 1000gr",
        img: quesoDobleCrema2,
    },
    {
        nombre: "Queso doble crema 250gr",
        img: quesoDobleCrema3,
    },
]

//Quesos madurados
const quesosMaduradosProductos = [
    {
        nombre: "Queso holandes colanta 2.5kg",
        img: quesosMadurados1,
    },
    {
        nombre: "Queso holandes colanta 475gr ",
        img: quesosMadurados2,
    },
    {
        nombre: "Queso holandes colanta 250gr",
        img: quesosMadurados3,
    },
    {
        nombre: "Queso holandes colanta 200gr",
        img: quesosMadurados4,
    },
    {
        nombre: "Queso monterey jack colanta 250gr",
        img: quesosMadurados5,
    },
    {
        nombre: "Queso monterey jack colanta 475gr",
        img: quesosMadurados6,
    },
    {
        nombre: "Queso centenario colanta 475gr",
        img: quesosMadurados7,
    },
    {
        nombre: "Queso centenario colanta 250gr",
        img: quesosMadurados8,
    },
    {
        nombre: "Queso chedar colanta 200gr",
        img: quesosMadurados9,
    },
]

//Quesos madurados
const quesoParmesanoProductos = [
    {
        nombre: "Queso parmesano colanta 1000gr",
        img: quesoParmesano1,
    },
    {
        nombre: "Queso parmesano colanta 500gr ",
        img: quesoParmesano2,
    },
    {
        nombre: "Queso parmesano colanta 250gr",
        img: quesoParmesano4,
    },
    {
        nombre: "Queso parmesano colanta 100gr",
        img: quesoParmesano5,
    },
    {
        nombre: "Queso parmesano colanta 40gr",
        img: quesoParmesano3,
    },
]


//Quesos cuñas y varios
const quesosCunasYvariosProductos = [
    {
        nombre: "Queso cheddar colanta cuña",
        img: quesoCunasVarios1,
    },
    {
        nombre: "Queso pecorino colanta cuña",
        img: quesoCunasVarios2,
    },
    {
        nombre: "Queso entrerios colanta cuña",
        img: quesoCunasVarios3,
    },
    {
        nombre: "Queso gruyere colanta cuña",
        img: quesoCunasVarios4,
    },
    {
        nombre: "Queso emmental colanta cuña",
        img: quesoCunasVarios5,
    },
    {
        nombre: "Queso tilsit colanta cuña",
        img: quesoCunasVarios6,
    },
    {
        nombre: "Queso parmesano colanta cuña",
        img: quesoCunasVarios7,
    },
    {
        nombre: "Queso de asar colanta 500gr",
        img: quesoCunasVarios8,
    },
    {
        nombre: "Queso de asar colanta 250gr",
        img: quesoCunasVarios9,
    },
    {
        nombre: "Queso mozzarella bufala colanta 250gr",
        img: quesoCunasVarios10,
    },
    {
        nombre: "Queso fundido colanta 250gr",
        img: quesoCunasVarios11,
    },
]


//Quesos cuñas y varios
const untablesMantequillasProductos = [
    {
        nombre: "Sour cream colanta 400gr",
        img: untablesMantequillas2,
    },
    {
        nombre: "Sour cream colanta 200gr",
        img: untablesMantequillas1,
    },
    {
        nombre: "Souero costeño pasteurizado ayotabuey colanta 230gr",
        img: untablesMantequillas3,
    },
    {
        nombre: "Crema de queso slight colanta 230gr",
        img: untablesMantequillas4,
    },
    {
        nombre: "Crema de queso colanta 400gr",
        img: untablesMantequillas5,
    },
    {
        nombre: "Crema de queso colanta 230gr",
        img: untablesMantequillas6,
    },
    {
        nombre: "Crema de queso colanta 150gr",
        img: untablesMantequillas7,
    },
    {
        nombre: "Crema de queso colanta 15gr",
        img: untablesMantequillas8,
    },
    {
        nombre: "Mantequilla con sal colanta 125gr",
        img: untablesMantequillas10,
    },
    {
        nombre: "Mantequilla sin sal colanta 250gr",
        img: untablesMantequillas9,
    },
    {
        nombre: "Mantequilla sin sal colanta 10gr",
        img: untablesMantequillas12,
    },
    {
        nombre: "Mantequilla con sal colanta 250gr",
        img: untablesMantequillas11,
    },
    {
        nombre: "Mantequilla sin sal colanta x2 und colanta 250gr",
        img: untablesMantequillas14,
    },
    {
        nombre: "Mantequilla sin sal colanta x2 und colanta 250gr",
        img: untablesMantequillas13,
    }
]

//Crema de leche
const cremaDeLecheProductos = [
    {
        nombre: "Crema de leche colanta 970gr",
        img: cremaDeLeche1,
    },
    {
        nombre: "Crema de leche colanta 870gr",
        img: cremaDeLeche2,
    },
    {
        nombre: "Crema de leche colanta 175gr",
        img: cremaDeLeche3,
    },
]

//Productos institucionales
//Leche en polvo
const lecheEnPolvoInstitucionalProductos = [
    {
        nombre: "Leche en polvo slight colanta 25kg",
        img: lecheEnPolvoInstitucional1,
    },
    {
        nombre: "Leche en polvo entera colanta 25kg",
        img: lecheEnPolvoInstitucional2,
    },
]

//Arequipes institucionales
const arequipesInstitucionalProductos = [
    {
        nombre: "Arequipe solucion colanta 5kg",
        img: arequipeInstitucional1,
    },
    {
        nombre: "Arequipe normal colanta 5kg",
        img: arequipeInstitucional2,
    },
    {
        nombre: "Arequipe alta densidad colanta 10kg",
        img: arequipeInstitucional3,
    },
]

//Crema de leche institucional
const cremaDeLecheInstitucionalProductos = [
    {
        nombre: "Crema agria colanta 4kg",
        img: cremaDeLecheInstitucional1,
    },
    {
        nombre: "Crema agria colanta 4kg",
        img: cremaDeLecheInstitucional2,
    },
]

//Crema de leche institucional
const quesoInstitucionalProductos = [
    {
        nombre: "Queso costeño colanta 5kg",
        img: quesoInstitucional1,
    },
    {
        nombre: "Queso para uso industrial colanta 5kg",
        img: quesoInstitucional2,
    },
    {
        nombre: "Queso panaderia colanta 10kg",
        img: quesoInstitucional3,
    },
]

//Mantequilla pasteurizada
const mantequillaPasteurizadaProductos = [
    {
        nombre: "Mantequilla pasteurizada colanta 382oz",
        img: mantequillaPasteurizadaInstitucional1,
    },
]


//Yogures
//Yogurt con cereal
const yogurtConCerealProductos = [
    {
        nombre: "Yogurt con hojuelas colanta 700ml",
        img: yogurtCereal1,
    },
    {
        nombre: "Yogurt con arroz achocolatado colanta 700ml",
        img: yogurtCereal2,
    },
    {
        nombre: "Yogurt con granola colanta 700ml",
        img: yogurtCereal3,
    },
]

//Yagur
const yagurProductos = [
    {
        nombre: "Yagur vaso fresa colanta 145ml",
        img: yagur1,
    },
    {
        nombre: "Yagur vaso melocoton colanta 145ml",
        img: yagur2,
    },
    {
        nombre: "Yagur vaso mora colanta 145ml",
        img: yagur3,
    },
    {
        nombre: "Yagur bolsa melocoton colanta 850ml",
        img: yagur4,
    },
    {
        nombre: "Yagur bolsa fresa colanta 850ml",
        img: yagur5,
    },
    {
        nombre: "Yagur bolsa mora colanta 850ml",
        img: yagur6,
    },
]

//Kumis
const kumisProductos = [
    {
        nombre: "Kumis garrafa colanta 1000gr",
        img: kumis1,
    },
    {
        nombre: "Kumis vaso colanta 200gr",
        img: kumis2,
    },
    {
        nombre: "Kumis garrafa colanta 4ltrs",
        img: kumis3,
    },
]

//Yogurt colanta
const yogurtColantaProductos = [
    {
        nombre: "Yogurt vaso arequipe colanta 190ml",
        img: yogurtColanta1,
    },
    {
        nombre: "Yogurt vaso piña colada colanta 190ml",
        img: yogurtColanta2,
    },
    {
        nombre: "Yogurt vaso melocoton colanta 190ml",
        img: yogurtColanta3,
    },
    {
        nombre: "Yogurt vaso fresa colanta 190ml",
        img: yogurtColanta4,
    },
    {
        nombre: "Yogurt vaso mora colanta 190ml",
        img: yogurtColanta5,
    },
    {
        nombre: "Yogurt vaso guanabana colanta 190ml",
        img: yogurtColanta13,
    },
    {
        nombre: "Yogurt bolsa mora colanta 850ml",
        img: yogurtColanta6,
    },
    {
        nombre: "Yogurt bolsa fresa colanta 850ml",
        img: yogurtColanta7,
    },
    {
        nombre: "Yogurt bolsa melocoton colanta 850ml",
        img: yogurtColanta8,
    },
    {
        nombre: "Yogurt garrafa fresa colanta 900ml",
        img: yogurtColanta9,
    },
    {
        nombre: "Yogurt garrafa mora colanta 900ml",
        img: yogurtColanta10,
    },
    {
        nombre: "Yogurt garrafa arequipe colanta 900ml",
        img: yogurtColanta11,
    },
    {
        nombre: "Yogurt garrafa melocoton colanta 900ml",
        img: yogurtColanta12,
    },
    {
        nombre: "Yogurt garrafa guanabana colanta 900ml",
        img: yogurtColanta15,
    },
    {
        nombre: "Yogurt garrafa melocoton colanta 4000ml",
        img: yogurtColanta14,
    },
]

//Yogurt vida
const yogurtVidaProductos = [
    {
        nombre: "Yogur fresa vida colanta 100ml",
        img: yogurtVida1,
    },
    {
        nombre: "Yogur mora vida colanta 100ml",
        img: yogurtVida2,
    },
    {
        nombre: "Yogur melocoton vida colanta 100ml",
        img: yogurtVida3,
    },
    {
        nombre: "Yogur vida empaque x6 surtido colanta 100ml",
        img: yogurtVida4,
    },
]

//Yogurt bufala
const yogurtBufalaProductos = [
    {
        nombre: "Yogurt de bufala fresa colanta 170ml",
        img: yogurtBufala1,
    },
    {
        nombre: "Yogurt de bufala mora colanta 170ml",
        img: yogurtBufala2,
    },
    {
        nombre: "Yogurt de bufala maracuya colanta 170ml",
        img: yogurtBufala3,
    },
]

//Yogurt slight
const yogurtSligthProductos = [
    {
        nombre: "Yogurt slight vaso fresa colanta 190ml",
        img: yogurtSlight1,
    },
    {
        nombre: "Yogurt slight vaso mora colanta 190ml",
        img: yogurtSlight2,
    },
    {
        nombre: "Yogurt slight melocoton colanta 190ml",
        img: yogurtSlight3,
    },
    {
        nombre: "Yogurt slight bolsa melocoton colanta 900ml",
        img: yogurtSlight4,
    },
    {
        nombre: "Yogurt slight bolsa fresa colanta 900ml",
        img: yogurtSlight5,
    },
    {
        nombre: "Yogurt slight vaso mora colanta 900ml",
        img: yogurtSlight6,
    },
]

//Paquetes de yogurt
const paquetesYogurtProductos = [
    {
        nombre: "Yagur x6 und colanta 200ml",
        img: paquetesYogurt1,
    },
    {
        nombre: "Yagur x7 und colanta 200ml",
        img: paquetesYogurt2,
    },
    {
        nombre: "Yogur x6 und colanta 190ml",
        img: paquetesYogurt3,
    },
    {
        nombre: "Yogur colanta slight x 6 und colanta 200ml",
        img: paquetesYogurt4,
    },
    {
        nombre: "Kumis x 6 und colanta 200ml",
        img: paquetesYogurt7,
    },
]

//Yogurt griego
const yogurtGriegoProductos = [
    {
        nombre: "Yogur griego limon colanta125ml",
        img: yogurtGriego1,
    },
    {
        nombre: "Yogur griego melocoton viad colanta100ml",
        img: yogurtGriego2,
    },
    {
        nombre: "Yogur griego vainilla colanta125ml",
        img: yogurtGriego3,
    },
    {
        nombre: "Yogur griego fresa colanta125ml",
        img: yogurtGriego4,
    },
]

//Yogurt benecol
const yogurtBenecolProductos = [
    {
        nombre: "Yogur benecol garrafa fresa colanta 1000gr",
        img: yogurtBenecol1,
    },
    {
        nombre: "Yogur benecol garrafa frutos rojos colanta 1000gr",
        img: yogurtBenecol2,
    },
    {
        nombre: "Yogur benecol garrafa frutos verdes colanta 1000gr",
        img: yogurtBenecol3,
    },
    {
        nombre: "Yogur benecol garrafa melocoton colanta 1000gr",
        img: yogurtBenecol4,
    },
]

//Kids
const kidsProductos = [
    {
        nombre: "Petito colanta 100gr",
        img: kids1,
    },
    {
        nombre: "Petito colanta 100gr",
        img: kids2,
    },
    {
        nombre: "Petito colanta 100gr",
        img: kids3,
    },
    {
        nombre: "Frescolanta vainilla bolsa 200ml",
        img: kids4,
    },
    {
        nombre: "Frescolanta fresa bolsa 200ml",
        img: kids5,
    },
    {
        nombre: "Frescolanta chocolate bolsa 200ml",
        img: kids6,
    },
    {
        nombre: "Frescolanta vainilla caja 200ml",
        img: kids7,
    },
    {
        nombre: "Frescolanta chocolate caja 200ml",
        img: kids8,
    },
    {
        nombre: "Frescolanta fresa * caja 200ml",
        img: kids9,
    },
    {
        nombre: "Gelatina twisty fresa colanta",
        img: kids10,
    },
    {
        nombre: "Gelatina twisty uva colanta 120gr",
        img: kids11,
    },
    {
        nombre: "Gelatina twisty naranja colanta 120gr",
        img: kids12,
    },
    {
        nombre: "Gelatina twisty mora colanta 120gr",
        img: kids13,
    },
    {
        nombre: "Arequipe kipe colanta 50gr",
        img: kids14,
    },
    // {
    //     nombre: "Arequipe kipe colanta 20gr",
    //     img: kids15,
    // },
    {
        nombre: "Arequipe kipe x 11 colanta 20gr",
        img: kids16,
    },
    {
        nombre: "Queso doblecrema ganitas colanta 50gr",
        img: kids17,
    },
]

//Arequipes y leches condensadas
const arequipesLechesCondensadasProductos = [
    {
        nombre: "Arequipe colanta 250gr",
        img: arequipes1,
    },
    {
        nombre: "Arequipe colanta 1kg",
        img: arequipes2,
    },
    {
        nombre: "Arequipe colanta 450gr",
        img: arequipes3,
    },
    {
        nombre: "Arequipe colanta 250gr",
        img: arequipes4,
    },
    {
        nombre: "Leche condensada colanta 1000gr",
        img: arequipes5,
    },
    {
        nombre: "Leche condensada colanta 300gr",
        img: arequipes6,
    },
    {
        nombre: "Manjar blanco colanta 250gr",
        img: arequipes7,
    },
    {
        nombre: "Miel colanta 200gr",
        img: arequipes8,
    },
    {
        nombre: "Arequipe bufala colanta 270gr",
        img: arequipes9,
    },
    {
        nombre: "Leche condensada x 12 und 20gr",
        img: arequipes10,
    },
]

//Carnicos
//Carnes frias
const carnesFriasProductos = [
    {
        nombre: "Tocineta colanta 250gr",
        img: carnesFrias1,
    },
    {
        nombre: "Tocineta colanta 900gr",
        img: carnesFrias2,
    },
    {
        nombre: "Tocineta colanta 450gr",
        img: carnesFrias3,
    },
    {
        nombre: "Salchicha XL colanta 1000gr",
        img: carnesFrias4,
    },
    {
        nombre: "Salchicha seleccionada colanta 450gr",
        img: carnesFrias5,
    },
    {
        nombre: "Jamon seleccionado colanta 450gr",
        img: carnesFrias6,
    },
    {
        nombre: "Mortadela seleccionada colanta 450gr",
        img: carnesFrias7,
    },
    {
        nombre: "Salchicho cervecero premium colanta 50gr",
        img: carnesFrias8,
    },
    {
        nombre: "Morcilla colanta 500gr",
        img: carnesFrias9,
    },
    {
        nombre: "Black pudding colanta 300gr",
        img: carnesFrias10,
    },
    {
        nombre: "Salchichon fino colanta 1000gr",
        img: carnesFrias11,
    },
    {
        nombre: "Salchichon fino colanta 450gr",
        img: carnesFrias12,
    },
    {
        nombre: "Salchichon fino colanta 225gr",
        img: carnesFrias13,
    },
    {
        nombre: "Salchichon fino colanta 125gr",
        img: carnesFrias14,
    },
    {
        nombre: "Salchichon cervecero colanta 500gr",
        img: carnesFrias16,
    },
    {
        nombre: "Salchichon montefrio colanta 225gr",
        img: carnesFrias17,
    },
    {
        nombre: "Salchichon montefrio colanta 500gr",
        img: carnesFrias18,
    },
    {
        nombre: "Carne de hamburguesa montefrio colanta 500gr",
        img: carnesFrias19,
    },
    {
        nombre: "Carne de hamburguesa premium colanta 500gr",
        img: carnesFrias20,
    },
    {
        nombre: "Salchicha wets montefrio colanta 430gr",
        img: carnesFrias21,
    },
    {
        nombre: "Chorizo de ternera colanta 450gr",
        img: carnesFrias22,
    },
    {
        nombre: "Chorizo coctel jalapeño colanta 300gr",
        img: carnesFrias23,
    },
    {
        nombre: "chorizo santarrosano colanta 500gr",
        img: carnesFrias24,
    },
    // {
    //     nombre: "Queso doble crema ganitascolanta 50gr",
    //     img: carnesFrias25,
    // },
    {
        nombre: "chorizo campesino colanta 450gr",
        img: carnesFrias26,
    },
    {
        nombre: "Salchicha tipica colanta 225gr",
        img: carnesFrias27,
    },
    {
        nombre: "Salchicha tipica colanta 250gr",
        img: carnesFrias28,
    },
    {
        nombre: "Butifarra colanta 450gr",
        img: carnesFrias29,
    },
    {
        nombre: "Carnicos para brochetas colanta 500gr",
        img: carnesFrias30,
    },
    {
        nombre: "Salchicha viena colanta 170gr",
        img: carnesFrias31,
    },
    {
        nombre: "Chorizo campesino colanta 250gr",
        img: carnesFrias32,
    },
    {
        nombre: "Mortadela seleccionada colanta 225gr",
        img: carnesFrias33,
    },
    {
        nombre: "Jamon seleccionado colanta 225gr",
        img: carnesFrias34,
    },
    {
        nombre: "Chorizo santarrosano tajado 225gr",
        img: carnesFrias35,
    },
]

//Carnicos institucionales
const carnicosInstitucionalesProductos = [
    {
        nombre: "Jamon seleccionado colanta 902gr",
        img: carnicosInstitucionales1,
    },
    {
        nombre: "Chorizo de ternera premium colanta 2.813kg",
        img: carnicosInstitucionales2,
    },
    {
        nombre: "Chorizo campesino colanta 2.5kg",
        img: carnicosInstitucionales3,
    },
    {
        nombre: "Chorizo con ternera colanta 1125gr",
        img: carnicosInstitucionales4,
    },
    {
        nombre: "Chorizo santarosano colanta 1125gr",
        img: carnicosInstitucionales5,
    },
    {
        nombre: "Morcilla colanta 1125kg",
        img: carnicosInstitucionales6,
    },
]

//Carne al vacio
const carneAlVacioProductos = [
    {
        nombre: "Carne de cerdo colanta 400gr",
        img: carnesAlVacio1,
    },
    {
        nombre: "Carne de res colanta 400gr",
        img: carnesAlVacio2,
    },
    {
        nombre: "Costillas de cerdo colanta 500gr",
        img: carnesAlVacio3,
    },
    {
        nombre: "Tocino jamonado colanta 400gr",
        img: carnesAlVacio4,
    },
]



//Tampico
const tampicoProductos = [
    {
        nombre: "Tampico colanta garrafa 1000ml",
        img: tampico1,
    },
    {
        nombre: "Tampico colanta garrafa 2000ml",
        img: tampico2,
    },
    {
        nombre: "Tampico colanta garrafa 4000ml",
        img: tampico3,
    },
    {
        nombre: "Tampico colanta caja 200ml",
        img: tampico5,
    },
    {
        nombre: "Tampico colanta tarro 330ml",
        img: tampico6,
    },
    {
        nombre: "Tampico colanta tarro 500ml",
        img: tampico7,
    },
    {
        nombre: "Tampico colanta tarro 240ml",
        img: tampico8,
    },
    {
        nombre: "Tampico colanta tarro 250ml",
        img: tampico9,
    },
    {
        nombre: "Tampico colanta bolsa 200ml",
        img: tampico10,
    },
    {
        nombre: "Tampico colanta 0 azucar garrafa 2000ml",
        img: tampico11,
    },
    {
        nombre: "Tampico colanta 0 azucar tarro 500ml",
        img: tampico12,
    },
    // {
    //     nombre: "Tampico colanta 0 azucar tarro 1000ml",
    //     img: tampico13,
    // },
    {
        nombre: "Tampico colanta 0 azucar bolsa 250ml",
        img: tampico14,
    },
    {
        nombre: "Tampico colanta 0 azucar tarro 250ml",
        img: tampico15,
    },
    {
        nombre: "Tampico colanta 10 und caja 200ml",
        img: tampico16,
    },
    {
        nombre: "Tampico colanta 6 und caja 250ml",
        img: tampico17,
    },
    {
        nombre: "Tampico colanta caja 1000ml",
        img: tampico20,
    },
    {
        nombre: "Jugo de naranja en caja colanta 1000ml",
        img: tampico18,
    },
    {
        nombre: "Agua montefrio tarro 600ml",
        img: tampico19,
    },

]




//Avena
const avenaProductos = [
    {
        nombre: "Avena sabor canela colanta 200ml",
        img: avena1,
    },
    {
        nombre: "Avena sabor canela colanta 1000ml",
        img: avena2,
    },
    {
        nombre: "Avena sabor canela colanta 1100ml",
        img: avena3,
    },
    {
        nombre: "Avena sabor canela x 6 und colanta 200ml",
        img: avena4,
    },
]

//Prolinco
const prolincoProductos = [
    {
        nombre: "Leche entera UHT prolinco 900ml",
        img: prolinco1,
    },
    {
        nombre: "Leche entera UHT prolinco 400ml",
        img: prolinco2,
    },
    {
        nombre: "Leche deslactosada UHT prolinco 900ml",
        img: prolinco3,
    },
    {
        nombre: "Quesito prolinco 500ml",
        img: prolinco4,
    },
]


//Abarrotes
//Kellogg's
const kelloggsProductos = [
    {
        nombre: "Choco krispis kelloggs 30gr",
        img: kellogs1,
    },
    {
        nombre: "Choco krispis kelloggs 120gr",
        img: kellogs2,
    },
    {
        nombre: "Zucaritas kelloggs 30gr",
        img: kellogs3,
    },
    {
        nombre: "Zucaritas kelloggs 120gr",
        img: kellogs4,
    },
]

//Copelia
const copeliaProductos = [
    {
        nombre: "Copelia x50 und yupi 23gr",
        img: copelia1,
    },
    {
        nombre: "Copelia x24 und yupi 23gr",
        img: copelia2,
    },
    // {
    //     nombre: "Copelia yupi 23gr",
    //     img: copelia3,
    // }
]


//Aburra
const aburraProductos = [
    {
        nombre: "Salsa aji aburra 165ml",
        img: aburra1,
    },
    {
        nombre: "Salsa teriyaki aburra 190ml",
        img: aburra2,
    },
    {
        nombre: "Salsa soya aburra 165ml",
        img: aburra3,
    },
    {
        nombre: "Salsa para carnes aburra 185gr",
        img: aburra4,
    },
    {
        nombre: "Salsa negra aburra 165ml",
        img: aburra5,
    },
    {
        nombre: "Salsa abor a humo aburra 165ml",
        img: aburra6,
    },
    {
        nombre: "Salsa de ajo aburra 165ml",
        img: aburra7,
    },
    {
        nombre: "Salsa chimichurri aburra 165ml",
        img: aburra8,
    },
    {
        nombre: "Salsa bbq aburra 190ml",
        img: aburra9,
    },
    {
        nombre: "Arvejas con zanahoria aburra 580gr",
        img: aburra10,
    },
    {
        nombre: "Maiz tierno aburra 340gr",
        img: aburra11,
    },
    {
        nombre: "Frijoles antioqueños con tocino aburra 300gr",
        img: aburra12,
    },
    {
        nombre: "Lentejas aburra 500gr",
        img: aburra13,
    },
    {
        nombre: "Atun marcela en aceite aburra 150gr",
        img: aburra14,
    },
    {
        nombre: "Atun marcela en agua aburra 150gr",
        img: aburra15,
    },
    {
        nombre: "Atun marcela en aceite x3 und aburra 150gr",
        img: aburra16,
    },
    {
        nombre: "Sardinas en salsa de tomate aburra 125gr",
        img: aburra17,
    },
    {
        nombre: "Sal refinada aburra 500gr",
        img: aburra18,
    },
    {
        nombre: "Salchicas viena pollo zenu 150gr",
        img: aburra19,
    },
    {
        nombre: "Lentejas con papa y zanahoria aburra 300gr",
        img: aburra20,
    }
]

//Vive100-Saviloe
const saviloeVive100Productos = [
    {
        nombre: "Saviloe con fibra 320 ml",
        img: saviloe1,
    },
    {
        nombre: "Vive100 380ml",
        img: saviloe2,
    },
];




//Bamby
const bambyProductos = [
    {
        nombre: "Aromatica manzanilla x20 und bamby 5gr",
        img: bamby1,
    },
    {
        nombre: "Aromatica yerbabuena x20 und bamby 5gr",
        img: bamby2,
    },
];

//El trapiche
const trapicheProductos = [
    {
        nombre: "Aromatica manzanilla x20 und bamby 5gr",
        img: trapiche1,
    },
    {
        nombre: "Aromatica yerbabuena x20 und bamby 5gr",
        img: trapiche2,
    },
];

//Pompeya
const pompeyaProductos = [
    {
        nombre: "Salsa de bbq pompeya 4000gr",
        img: pompeya1,
    },
    {
        nombre: "Salsa de piña pompeya 4000gr",
        img: pompeya2,
    },
    {
        nombre: "Salsa mostaza pompeya 4000gr",
        img: pompeya3,
    },
    {
        nombre: "Salsa mayonesa pompeya 4000gr",
        img: pompeya4,
    },
    {
        nombre: "Salsa mayomostaza pompeya 4000gr",
        img: pompeya5,
    },
    {
        nombre: "Salsa de tomate pompeya 4000gr",
        img: pompeya6,
    },
];

//Bary
const baryProductos = [
    {
        nombre: "Salsa de tomate paquete x 50 und bary 8gr",
        img: bary1,
    },
    {
        nombre: "Salsa rosada paquete x 50 und bary 8gr",
        img: bary2,
    },
];


//Aderezos
const aderezosProductos = [
    {
        nombre: "Salsa de tomate aderezos 1000gr",
        img: aderezos1,
    },
    {
        nombre: "Salsa rosada aderezos 1000gr",
        img: aderezos2,
    },
    {
        nombre: "Salsa de chocolate aderezos 1000gr",
        img: aderezos3,
    },
    {
        nombre: "Vinagre aderezos 3340gr",
        img: aderezos4,
    },
];


//Oreo Tosh
const oreoToshProductos = [
    {
        nombre: "Galletas oreo x 12 und oreo 432gr",
        img: oreo1,
    },
    {
        nombre: "Galletas oreo x 6 und oreo 216gr",
        img: oreo2,
    },
    {
        nombre: "Galletas tohs miel x 9 und tosh 243gr",
        img: oreo3,
    },
];

//Festival
const festivalProductos = [
    {
        nombre: "Festival x12 und vainilla noel",
        img: festival1,
    },
    {
        nombre: "Festival x12 und limon noel",
        img: festival2,
    },
    {
        nombre: "Festival x12 und fresa noel",
        img: festival3,
    },
    {
        nombre: "Festival x12 und chocolate noel",
        img: festival4,
    },
];


//Club social
const clubSocialProductos = [
    {
        nombre: "Galletas original x9 und club social",
        img: clubSocial1,
    },
    {
        nombre: "Galletas integral x9 und club social",
        img: clubSocial2,
    },
];

//Colanta
const colantaAbarrotesProductos = [
    {
        nombre: "Arroz colanta",
        img: colanta1,
    }
];

//Konfyt

//Gelatinas
const gelatinasProductos = [
    {
        nombre: "Postre de melocoton konfyt 11gr",
        img: gelatinas1,
    },
    {
        nombre: "Postre de fresa konfyt 11gr",
        img: gelatinas2,
    },
    {
        nombre: "Postre de mora konfyt 11gr",
        img: gelatinas3,
    },
    {
        nombre: "Pudin de vainilla konfyt 24gr",
        img: gelatinas4,
    },
    {
        nombre: "Pudin de chocolate konfyt 24gr",
        img: gelatinas5,
    },
    {
        nombre: "Pudin de arequipe konfyt 24gr",
        img: gelatinas6,
    },
    {
        nombre: "Gelatina sabores surtidos x4 pompeya",
        img: gelatinas7,
    },
];

//Mermeladas
const mermeladasProductos = [
    {
        nombre: "Arequipe konfyt 280gr",
        img: mermeladas1,
    },
    {
        nombre: "Mermelada de fresa konfyt 150gr",
        img: mermeladas2,
    },
    {
        nombre: "Mermelada de mora konfyt 250gr",
        img: mermeladas3,
    },
    {
        nombre: "Mermelada de piña konfyt 250gr",
        img: mermeladas4,
    },
    {
        nombre: "Dulce de brevas konfyt 500gr",
        img: mermeladas5,
    },
];


//Dulces
const dulcesProductos = [
    {
        nombre: "Caramelos de leche konfyt 30gr",
        img: dulces1,
    },
    {
        nombre: "Dilce de guayaba konfyt 220gr",
        img: dulces2,
    },
];




//Natillas y pancakes
const natillasPancakesProductos = [
    {
        nombre: "Natilla tradicional canela konfyt 120gr",
        img: natillas1,
    },
    {
        nombre: "Natilla arequipe konfyt 120gr",
        img: natillas2,
    },
    {
        nombre: "Natilla coco konfyt 120gr",
        img: natillas3,
    },
    {
        nombre: "pancakes konfyt 300gr",
        img: natillas4,
    },
    {
        nombre: "Miel syrup kvital konfyt 360gr",
        img: natillas5,
    },
];



//Sucralosa
const sucralosaProductos = [
    {
        nombre: "Sucralosa x 250 und konfyt 100gr",
        img: sucrasola1,
    },
    {
        nombre: "Sucralosa x 250 und konfyt 70gr",
        img: sucrasola2,
    },
];

//Sin azucar añadido
const sinAzucarAnadidoProductos = [
    {
        nombre: "Dulce con leche queso urabeño konfyt 180gr",
        img: sinAzucarAnadida1,
    },
    {
        nombre: "Galletas sabor a matequilla konfyt 140gr",
        img: sinAzucarAnadida2,
    },
];


//Aseo
//Gutvan
const gutvanProductos = [
    {
        nombre: "Vaso desechable blanco gutvan #6",
        img: gutvan1,
    },
    {
        nombre: "Vaso desechable blanco gutvan #7",
        img: gutvan2,
    },
    {
        nombre: "Vaso desechable transparente gutvan #7",
        img: gutvan3,
    },
    {
        nombre: "Vaso desechable transparente gutvan #10",
        img: gutvan4,
    },
    {
        nombre: "Vaso desechable transparente gutvan #12",
        img: gutvan5,
    },
    {
        nombre: "Vaso desechable transparente gutvan #14",
        img: gutvan6,
    },
    {
        nombre: "Vaso desechable transparente gutvan #16",
        img: gutvan7,
    },
    {
        nombre: "Tapa de vaso gutvan",
        img: gutvan8,
    },
];

//Nube
const nubeProductos = [
    {
        nombre: "Paquete de servilletas x 300 nube",
        img: nube1,
    },
    {
        nombre: "Caja de servilletas x 15 pqt nube",
        img: nube2,
    },
];

//Fassi
const fassiProductos = [
    {
        nombre: "Jabon lava platos fassi colanta 900gr",
        img: fassi1,
    },
];

//Incauca
const incaucaProductos = [
    {
        nombre: "Azucar blanca incauca 2.5kg",
        img: incauca1,
    },
    {
        nombre: "Paquete de turbipaks x 200 incauca",
        img: incauca2,
    },
];


//Members selection
const membersSelectionProductos = [
    {
        nombre: "Endulzante sweestener 2000gr",
        img: members1,
    },
];




//Yupi
//Loncheras
const loncherasProductos = [
    {
        nombre: "Rizadas mayonesa x13 und yupi 251gr",
        img: loncheras1,
    },
    {
        nombre: "Lonchera diverpack x25 und yupi 483gr",
        img: loncheras2,
    },
    {
        nombre: "Lonchera diverpack x30 und yupi 594gr",
        img: loncheras3,
    },
];

//Rizadas
const rizadasProductos = [
    {
        nombre: "Rizadas mayonesa x12 und yupi 300gr",
        img: rizadas1,
    },
    {
        nombre: "Rizadas mayonesa yupi 105gr",
        img: rizadas6,
    },
    {
        nombre: "Rizadas naturales x12 und yupi 300gr",
        img: rizadas2,
    },
    {
        nombre: "Rizadas natural yupi 105gr",
        img: rizadas7,
    },
    {
        nombre: "Rizadas limon x12 und yupi 300gr",
        img: rizadas3,
    },
    {
        nombre: "Rizadas limon yupi 105gr",
        img: rizadas5,
    },
    {
        nombre: "Rizadas tomate x12 und yupi 300gr",
        img: rizadas4,
    },
    {
        nombre: "Rizadas tomate yupi 105gr",
        img: rizadas8,
    },
];

//Palomitas y tocinetas
const palomitasTocinetasProductos = [
    {
        nombre: "Tozinetas fred x12 und yupi 156gr",
        img: palomitas1,
    },
    {
        nombre: "Palomitas x12 und yupi 240gr",
        img: palomitas2,
    },
    {
        nombre: "Palomitas caramelo yupi 170gr",
        img: palomitas3,
    },
    {
        nombre: "Palomitas galleta con crema yupi 55gr",
        img: palomitas4,
    },
];

//Golpe
const golpeProductos = [
    {
        nombre: "Golpe original x 8und yupi 45gr",
        img: golpe1,
    },
    {
        nombre: "Golpe original yupi 140gr",
        img: golpe2,
    },
    {
        nombre: "Golpe natural yupi 45gr",
        img: golpe3,
    },
    {
        nombre: "Golpe natural yupi 140gr",
        img: golpe4,
    },
    {
        nombre: "Golpe BBQ yupi 45gr",
        img: golpe5,
    },
    {
        nombre: "Golpe BBQ yupi 140gr",
        img: golpe6,
    },
    {
        nombre: "Golpe limon x 8und yupi 45gr",
        img: golpe7,
    },
    {
        nombre: "Golpe limon yupi 140gr",
        img: golpe8,
    },
    {
        nombre: "Golpe ranchero x 8und yupi 45gr",
        img: golpe9,
    },
    {
        nombre: "Golpe ranchero yupi 140gr",
        img: golpe10,
    },
];

//Tosti
const tostiProductos = [
    {
        nombre: "Tosti empanadas limon 25gr",
        img: tosti1,
    },
    {
        nombre: "Tosti empanadas limon 150gr",
        img: tosti2,
    },
    {
        nombre: "Tosti empanadas carne 128gr",
        img: tosti3,
    },
    {
        nombre: "Tosti picanticos hot x12und yupi 128gr",
        img: tosti4,
    },
    {
        nombre: "Tosti picanticos medium x12und yupi 128gr",
        img: tosti5,
    },
    {
        nombre: "Tosti nachos yupi 180gr",
        img: tosti6,
    },
    {
        nombre: "Tosti pizza queso x12und yupi 128gr",
        img: tosti7,
    },
    {
        nombre: "Tosti arepa x12und yupi 128gr",
        img: tosti8,
    },
];

//Yupis
const yupisProductos = [
    {
        nombre: "Yupis picantes yupi 44gr",
        img: yupis1,
    },
    {
        nombre: "Yupis salados x3und yupi 44gr",
        img: yupis2,
    },
];

//Clasicas
const clasicasProductos = [
    {
        nombre: "Clasicas limon x8 und yupi 36gr",
        img: clasicas1,
    },
    {
        nombre: "Clasicas mayonesa x8 und yupi 36gr",
        img: clasicas2,
    },
    {
        nombre: "Clasicas pollo a la bbq x8 und yupi 36gr",
        img: clasicas3,
    },
];


//Mekato
const mekatoProductos = [
    {
        nombre: "Mekato limon x12 und 25gr",
        img: mekato1,
    },
    {
        nombre: "Mekato limon yupi 100gr",
        img: mekato2,
    },
    {
        nombre: "Crespas natural x12 und 25gr",
        img: mekato3,
    },
];

//Varios-yupi
const variosYupiProductos = [
    {
        nombre: "Anillos de cebolla x 8und yupi 21gr",
        img: variosYupi1,
    },
    {
        nombre: "Multicereal x 8und yupi 38gr",
        img: variosYupi2,
    },
    {
        nombre: "Lizas natural tomate x12 und yupi 27gr",
        img: variosYupi3,
    },
];




export { quesoDobleCremaProductos, quesitosCuajadasProductos, quesoMozzarellaProductos, quesoColantaProdcutos, lecheEnPolvoProductos,lecheUHTProductos, quesosMaduradosProductos, quesoParmesanoProductos, quesosCunasYvariosProductos, untablesMantequillasProductos, cremaDeLecheProductos, yogurtBufalaProductos, yogurtConCerealProductos, yogurtGriegoProductos, yogurtVidaProductos, avenaProductos, kidsProductos, arequipesLechesCondensadasProductos, paquetesYogurtProductos, kelloggsProductos, copeliaProductos, aburraProductos, bambyProductos, trapicheProductos, pompeyaProductos, baryProductos, aderezosProductos, oreoToshProductos, festivalProductos, clubSocialProductos, sucralosaProductos, gelatinasProductos, natillasPancakesProductos, mermeladasProductos, dulcesProductos, saviloeVive100Productos, loncherasProductos, rizadasProductos, palomitasTocinetasProductos, sinAzucarAnadidoProductos, carnesFriasProductos,carnicosInstitucionalesProductos,  colantaAbarrotesProductos, tampicoProductos, yogurtBenecolProductos, yogurtSligthProductos, yogurtColantaProductos, kumisProductos, yagurProductos, lecheEnPolvoInstitucionalProductos, arequipesInstitucionalProductos, cremaDeLecheInstitucionalProductos, quesoInstitucionalProductos, gutvanProductos, nubeProductos, fassiProductos, incaucaProductos, carneAlVacioProductos, mantequillaPasteurizadaProductos, prolincoProductos, golpeProductos, tostiProductos, yupisProductos, membersSelectionProductos, lecheEnPolvoMontefrioProductos, clasicasProductos, mekatoProductos, variosYupiProductos };