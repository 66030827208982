import React, { useEffect, useState } from 'react';


//Redux
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';


//librerias
import { secciones } from '../lib/secciones';
import Productos from '../components/Productos';

//Imagenes
import logoPrincipal from '../images/Globales/logo-flecha.png';
import logoKonfyt from '../images/Secciones/Konfyt/Menu Konfyt-10.png';
import logoYupi from '../images/Secciones/yupi/yupi.png';
import logoColanta from '../images/Colanta/Menu principal/logo colanta png.png';

const SeccionProductos = () => {
    const [ mostrarProductos, setMostrarProductos ] = useState(false);

    //Traemos el estado de la store donde el usuario eligio una opcion.
    let eleccion = useSelector(state => state.eleccionIndex.eleccion);



    //Pruebas
    useEffect(() => {
        if(eleccion === "lecheUHT" || eleccion === "untablesMantequillas" || eleccion === "cremaDeLeche" || eleccion === "kids" || eleccion === "arequipesLecheCondensada" || eleccion === "tampico" || eleccion === "avena" || eleccion === "prolinco"){
            setMostrarProductos(true);
        }
    }, [])


    const navegar = useNavigate();

    //Funcion para ir al index de la pagina
    const irIndex = () => {
        navegar('/');
    }

    //Funcion para ir a la seccion colanta de la pagina
    const irColanta = () => {
        navegar('/colanta');
    }

    //Funcion que cambia el componente mostrado por los productos.
    const mostrarComponenteProductos = () => {
        setMostrarProductos(true);
    };


    //Creamos un if que valida si hay una eleccion en la app y si la hay sigue y genera las opciones de las secciones sino envia al usuario a la pagina index.
    let seccionElegida;
    let seccionesMostrar;
    if(!eleccion){
        seccionesMostrar = <Navigate to='/'/>
    }else{
        seccionElegida = secciones[eleccion];
        seccionesMostrar = seccionElegida.contenido.map(seccion => {
            return <a className='seccion__a--opcion' onClick={() => mostrarComponenteProductos()} href={`#${seccion.nombre}`}><img loading='lazy' width="220px"  src={seccion.img} alt='img'/></a>
        });
    }


    //Creamos una funcion que verifica que tipo de seccion es y nos devuelve la estructura con sus cambios
    const componenteSeccionParteAlta = (eleccion) => {
        let result;
        if(eleccion === "abarrotes"){
            result = (
                <div onClick={() => irIndex()} className="seccion__div--containerAbarrotes">
                    {mostrarProductos ? (""):(<div className='seccion__div--container-logo-fixed-abarrotes'>
                        <div className='seccion__div--logo-container-abarrotes'>
                            <img loading='lazy' src={logoPrincipal} alt='logo'/>
                        </div>
                        <span className='seccion__span--adorno-linea-blanca'></span>
                    </div>)}
                </div>
            )
        }else if(eleccion === "konfyt"){
            result = (
                <div onClick={() => irIndex()} className="seccion__div--containerKonfyt-alta">
                {mostrarProductos ? (""):(<div className='seccion__div--container-logo-fixed-konfyt'>
                        <div className='seccion__div--logo-container-konfyt'>
                            <img loading='lazy' src={logoPrincipal} alt='logo'/>
                            <img loading='lazy' src={logoKonfyt} alt='logo-konfyt'/>
                        </div>
                        <span className='seccion__span--adorno-linea-azul-konfyt'></span>
                    </div>)}
                </div>
            )
        }else if(eleccion === "aseo"){
            result = (
                <div onClick={() => irIndex()} className="seccion__div--containerAseo">
                    {mostrarProductos ? (""):(<div className='seccion__div--container-logo-fixed-aseo'>
                        <div className='seccion__div--logo-container-aseo'>
                            <img loading='lazy' src={logoPrincipal} alt='logo'/>
                        </div>
                        <span className='seccion__span--adorno-linea-blanca1-aseo'></span>
                    </div>)}
                </div>
            )
        }else if(eleccion === "yupi"){
            result = (
                <div onClick={() => irIndex()} className="seccion__div--containerYupi-alta">
                {mostrarProductos ? (""):(<div className='seccion__div--container-logo-fixed-yupi'>
                        <div className='seccion__div--logo-container-yupi'>
                            <img loading='lazy' src={logoPrincipal} alt='logo'/>
                            <img loading='lazy' src={logoYupi} alt='logo-yupi'/>
                        </div>
                        <span className='seccion__span--adorno-linea-blanca-yupi'></span>
                    </div>)}
                </div>
            )
        }else if(eleccion === "prolinco" || eleccion === "quesos" || eleccion === "productosInstitucionales" || eleccion === "yogures" || eleccion === "carnicos" || eleccion === "lecheEnPolvo" || eleccion === "lecheUHT" || eleccion === "untablesMantequillas" || eleccion === "cremaDeLeche" || eleccion === "tampico"  || eleccion === "avena" || eleccion === "kids" || eleccion === "arequipesLecheCondensada" || eleccion === "abarrotes"){
            result = (
                <div onClick={() => irColanta()} className="seccion__div--containerColanta-alta">
                {mostrarProductos ? (""):(<div className='seccion__div--container-logo-fixed-colanta'>
                        <div className='seccion__div--logo-container-colanta'>
                            <img loading='lazy' src={logoPrincipal} alt='logo'/>
                            <img loading='lazy' src={logoColanta} alt='logo-colanta'/>
                        </div>
                        <span className='seccion__span--adorno-linea-blanca-colanta'></span>
                    </div>)}
                </div>
            )
        }

        return result;
    }


    //Creamos una funcion que verifica que tipo de seccion es y nos devuelve la estructura con sus cambios
    const componenteSeccionParteBaja = (eleccion) => {
        let result;
        if(eleccion === "abarrotes"){
            result = (
                <div className={mostrarProductos ? (""):("seccion__div--containerAbarrotes")}>
                    <div className={mostrarProductos ? (""):('seccion__div--container-opciones')}>
                        {mostrarProductos ? (<Productos setMostrarProductos={setMostrarProductos} seccionElegida={seccionElegida} mostrarProductos={mostrarProductos} eleccion={eleccion}/>):(seccionesMostrar)}
                    </div>
                </div>
            )
        }else if(eleccion === "konfyt"){
            result = (
                <div className={mostrarProductos ? (""):("seccion__div--containerKonfyt-baja")}>
                    <div className={mostrarProductos ? (""):('seccion__div--container-opciones-konfyt')}>
                        {mostrarProductos ? (<Productos setMostrarProductos={setMostrarProductos} seccionElegida={seccionElegida} mostrarProductos={mostrarProductos} eleccion={eleccion}/>):(seccionesMostrar)}
                        <span className='seccion__span--adorno-linea-roja-konfyt'></span>
                    </div>
                </div>
            )
        }else if(eleccion === "aseo"){
            result = (
                <div className={mostrarProductos ? (""):("seccion__div--containerAseo-baja")}>
                    <div className={mostrarProductos ? (""):('seccion__div--container-opciones-aseo')}>
                        {mostrarProductos ? (<Productos setMostrarProductos={setMostrarProductos} seccionElegida={seccionElegida} mostrarProductos={mostrarProductos} eleccion={eleccion}/>):(seccionesMostrar)}
                        <span className='seccion__span--adorno-linea-blanca2-aseo'></span>
                    </div>
                </div>
            )
        }else if(eleccion === "yupi"){
            result = (
                <div className={mostrarProductos ? (""):("seccion__div--containerYupi-baja")}>
                    <div className={mostrarProductos ? (""):('seccion__div--container-opciones-yupi')}>
                        {mostrarProductos ? (<Productos setMostrarProductos={setMostrarProductos} seccionElegida={seccionElegida} mostrarProductos={mostrarProductos} eleccion={eleccion}/>):(seccionesMostrar)}
                        <span className='seccion__span--adorno-linea-blanca2-yupi'></span>
                    </div>
                </div>
            )
        }else if(eleccion === "quesos" || eleccion === "productosInstitucionales" || eleccion === "yogures" || eleccion === "carnicos" || eleccion === "lecheEnPolvo" || eleccion === "lecheUHT" || eleccion === "untablesMantequillas" || eleccion === "cremaDeLeche" ||  eleccion === "tampico" || eleccion === "avena" || eleccion === "kids" || eleccion === "arequipesLecheCondensada" || eleccion === "abarrotes" || eleccion === "prolinco"){
            result = (
                <div className={mostrarProductos ? (""):("seccion__div--containerColanta-baja")}>
                    <div className={mostrarProductos ? (""):('seccion__div--container-opciones-colanta')}>
                        {mostrarProductos ? (<Productos setMostrarProductos={setMostrarProductos} seccionElegida={seccionElegida} mostrarProductos={mostrarProductos} eleccion={eleccion}/>):(seccionesMostrar)}
                        <span className='seccion__span--adorno-linea-blanca2-colanta'></span>
                    </div>
                </div>
            )
        }


        return result;
    }



    return (
        <>
            {componenteSeccionParteAlta(eleccion)}
            {componenteSeccionParteBaja(eleccion)}
            {eleccion ? (""):(<h2>Redirigiendo...</h2>)}
        </>
    )
};

export default SeccionProductos