import React from 'react';

//Librerias
import { opciones } from '../lib/opcionesIndex';

//React Router dom
import { useNavigate } from 'react-router-dom';

//Redux
import { useDispatch } from "react-redux";
import { setEleccion } from '../store/slices/eleccionIndex';

//Imagenes
import logoPrincipal from '../images/Globales/Menu Principal-08.png';
import marcasAsociadas from '../images/Index/Menu Principal-10.png';



const Index = () => {

    const navegar = useNavigate();
    const dispatch = useDispatch();


    //Establecemos una funcion que toma el nombre de la seccion a la que se accedio y la establece en el store al igual que con el navegar nos envia a esa pantalla con la seccion electa.
    const irSeccion = (link, seccion) => {
        navegar(link);
        dispatch(setEleccion(
            seccion
        ))
    };

    //Creamos un map de botones que sera la seccion del index en donde daremos para ir a esa seccion y que ejecutara la funcion ehecha previamente.
    let opcionesIndex = opciones.map(opcion => {
        if(!opcion.img){
            return <button className='index__button--index' onClick={() => irSeccion(opcion.link, opcion.seccion)}>{opcion.nombre}</button>
        }else{
            return <button className='index__button--index' onClick={() => irSeccion(opcion.link, opcion.seccion)}><img src={opcion.img} alt={opcion.nombre}/></button>
        }
    });

    return (
        <div className='index__container'>
            <div className='index__div--container--logo-principal'>
                <img loading='lazy' src={logoPrincipal} alt='lacteos bombona logo'/>
            </div>
            <span className='index__span--adorno-linea-blanca'></span>
            <div className='index__div--container-menu'>
                {opcionesIndex}
            </div>
            <div className='index__div--container-marcas-asociadas'>
                <img loading='lazy' src={marcasAsociadas} alt='marcas-asociadas'/>
            </div>
        </div>
    )
};

export default Index;