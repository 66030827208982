import React, { useEffect, useState } from 'react';

//Imagenes
import Lupa from '../images/Globales/lupa.png';
import cerrar from '../images/Globales/cerrar.png';


import { useDispatch } from 'react-redux';
import { setBuscadorActivo } from '../store/slices/buscadorActivo';
import { setDatosEncontrados } from '../store/slices/datosEncontrados';


const Buscador = (props) => {
    const [ buscadorElegido, setBuscadorElegido ] = useState(false);
    const [ inputBuscador, setInputBuscador ] = useState("");

    //Traemos los props
    const { seccionElegida } = props;

    //Vamos a subir al store un estado global que dice si el buscador esta activo.
    const dispatch = useDispatch();

    //Si el usuario tipea algun caracter en el buscador se marca el estado global en activo en true y lo que hara es quitar los productos de la pantalla.
    useEffect(() => {
        if(inputBuscador.length > 0){
            dispatch(setBuscadorActivo(true));
            //Datos encontrados subidos a la store para mostrar en otro componente.
            dispatch(setDatosEncontrados(convertirDatosAComponente()));
        }else if(inputBuscador.length === 0){
            dispatch(setBuscadorActivo(false));
        };
    }, [inputBuscador]);

    //La funcion que acogera lo que pongamos en el input y lo transformara en minusculas.
    const handleInputChangeBuscador = (event) => {
        setInputBuscador(event.target.value.toLowerCase());
    };


    //Hacemos un ciclo for que va a pushear cada producto de la seccion en un array completo.
    let productos = [];
    for (let i = 0; i < seccionElegida.contenido.length; i++) {
        for (let j = 0; j < seccionElegida.contenido[i].productos.length; j++) {
            productos.push(seccionElegida.contenido[i].productos[j].nombre);
        }
    };

    // console.log("productos", productos);


    //Tenemos la funcion que filtra los datos.
    function filterItems(inputBuscador, datos) {
        return datos.filter(function(el) {
            return el.toLowerCase().indexOf(inputBuscador.toLowerCase()) > -1;
        })
    };


    //Creamos una funcion que cierra y limpia el buscador.
    const cerrarYLimpiarBuscador = () => {
        setBuscadorElegido(false)
        setInputBuscador("");
    }

    //Creamos la funcion que devuelve los datos completos de la busqueda.
    const encontrarDatos = (busqueda, datos) => {
        let datosMostrar = [];
        for (let i = 0; i < busqueda.length; i++) {
            for (let j = 0; j < datos.contenido.length; j++) {
                for (let k = 0; k < datos.contenido[j].productos.length; k++) {
                    if(busqueda[i] === datos.contenido[j].productos[k].nombre){
                        datosMostrar.push(datos.contenido[j].productos[k]);
                    };
                }
            }
        };
        return datosMostrar;
    };


    //Creamos una funcion que convierte los datos encontrados en etiquetas html para el buscador
    const convertirDatosAComponente = () => {
        let componenteMostrar = [];
        if(inputBuscador.length > 0){
            componenteMostrar = encontrarDatos( filterItems(inputBuscador, productos), seccionElegida).map(dato => {
                return <div className='buscador__div--datoEncontrado-container'>
                            <img src={dato.img} alt={dato.nombre}/>
                        </div>
            })
        }
        return componenteMostrar;
    };




    return (
        <div className='buscador__div--container'>
            <div className='buscador__div--buscador-lupa'>
                {buscadorElegido ? (
                    <>
                        <input value={inputBuscador} autoComplete='off' onChange={handleInputChangeBuscador}/>
                        <button className='buscador__button--cerrar-buscador' onClick={() => cerrarYLimpiarBuscador()}><img src={cerrar} alt='cerrar-buscador'/></button>
                    </>
                    ):(
                    <button className='buscador__button--lupa' onClick={() => setBuscadorElegido(true)}>
                        <img width="20px" src={Lupa} alt='lupa'/>
                    </button>
                )}
            </div>
        </div>
    )
};

export default Buscador;